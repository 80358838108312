import './SearchFormInput.css'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function SearchFormInput({ searchInput, setSearchInput, handleSubmit }) {
  const { t } = useTranslation()
  const [onFocus, setOnFocus] = useState(false)
  const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(80,80,80,0.9)',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(80,80,80,0.9)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(85, 130, 50)',
      },
    },
  })
  const classes = useStyles()

  const handleChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handleFocus = () => {
    setOnFocus(true)
  }

  const handleBlur = () => {
    setOnFocus(false)
  }

  const handleOnkeyDown = (event) => {
    if (event.keyCode === 8 && searchInput.length === 1) {
      setOnFocus(true)
    }
    if (event.keyCode === 13) {
      setOnFocus(false)
      handleSubmit(event)
    }
  }

  return (
    <form noValidate autoComplete="off" className="form-container" onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        id="custom-styled-outlined-input"
        label={onFocus === true || searchInput !== '' ? '' : t('commonComponent.toolBar.searchPlaceholder')}
        InputLabelProps={{ shrink: false }}
        value={searchInput}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleOnkeyDown}
        className={classes.root}
        focused={onFocus}
      />
    </form>
  )
}

export default SearchFormInput

import {
  GET_SYS_INFO_FAILED,
  GET_SYS_INFO_REQUEST,
  GET_SYS_INFO_SUCCESS,
} from 'constants/actionTypes/systemActionTypes'

function systemInfo(state = { system: {} }, action) {
  switch (action.type) {
    case GET_SYS_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_SYS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        system: action.payload,
      }

    case GET_SYS_INFO_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
export { systemInfo }

import {
  CLEAR_CUSTUSER,
  GET_CUSTOMER_NAME_FAILED,
  GET_CUSTOMER_NAME_REQUEST,
  GET_CUSTOMER_NAME_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'

const getCustomerName = (state = { customerName: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOMER_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_CUSTOMER_NAME_REQUEST,
      }
    case GET_CUSTOMER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        customerName: action.payload,
        type: GET_CUSTOMER_NAME_SUCCESS,
      }
    case GET_CUSTOMER_NAME_FAILED:
      return {
        ...state,
        loading: false,
        customerName: [],
        message: action.payload,
        type: GET_CUSTOMER_NAME_FAILED,
      }
    case CLEAR_CUSTUSER:
      return {
        ...state,
        loading: false,
        customerName: [],
        message: action.payload,
        type: CLEAR_CUSTUSER,
      }
    default:
      return state
  }
}

export { getCustomerName }

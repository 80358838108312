import './ModalConfirm.css'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { IMAGES } from 'assets'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Image from '../Image/Image'

function ModalConfirm({ title, showModal, closeModal, setShowModal, handleFunction, screen, buttonAlign = '' }) {
  const { t } = useTranslation()
  const setFocus = (posision) => {
    if (posision === 'begin') document.getElementById('btn-end').focus()
    if (posision === 'end') document.getElementById('btn-begin').focus()
  }
  const ref = useRef(null)
  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      disableEscapeKeyDown
      maxWidth={false}
      aria-labelledby="confirm-dialog-title"
      className="wrapper__dialog"
      id="modal__confirm"
    >
      <DialogTitle id="confirm-dialog-title" className="dialog__message-title">
        {t('commonComponent.modal.titleDialog')}
      </DialogTitle>
      <DialogContent className="dialog__message-content">
        <Image
          src={IMAGES.ICON.QUESTION_ICON}
          className="icon_warning"
          style={{ width: 18, height: 18, marginRight: 10 }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          className="text-center"
        ></div>
      </DialogContent>

      <DialogActions className={`dialog__message-action ${buttonAlign}`}>
        <div onFocus={() => setFocus('begin')} className="wrapper__opacity"></div>
        {/* Submit button */}
        <Button
          className="button-submit"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleFunction}
          ref={ref}
          tabIndex="0"
          id="btn-begin"
        >
          {/* {t('commonComponent.modal.lblSubmit')}
           */}
          {title === t('forgotPassword.lblUpdateSuccess') || screen === 'compliance'
            ? 'OK'
            : t('commonComponent.modal.lblSubmit')}
        </Button>
        {/* Cancel button */}
        {screen === 'compliance' ? (
          ''
        ) : (
          <Button
            className="button-cancel"
            color="primary"
            onKeyDown={(e) => {
              if (e.keyCode === 9) {
                ref.current.focus()
                e.preventDefault()
              }
            }}
            onClick={() => setShowModal(false)}
            tabIndex="0"
            id="btn-end"
          >
            {t('commonComponent.modal.lblCancel')}
          </Button>
        )}

        <div onFocus={() => setFocus('end')} className="wrapper__opacity"></div>
      </DialogActions>
    </Dialog>
  )
}

export default ModalConfirm

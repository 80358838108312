import './Navbar.css'

import { IconButton } from '@material-ui/core'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu'
import { logout } from 'actions/userActions'
import { formatDateWithPadding } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm } from 'components/commons'
import { JP_WEEK_DAYS } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import logoutImg from '../../../assets/img/sign-out-alt-solid.svg'
import userAvatar from '../../../assets/img/user_avatar.png'

let moment = require('moment-timezone')
moment.locale('ja')
moment.updateLocale('ja', {
  weekdaysShort: JP_WEEK_DAYS,
})

const getCurrentDate = (date = new Date()) => {
  const nowDate = new Date()
  const hours = nowDate.getHours()
  const minutes = nowDate.getMinutes()
  return new Date(date + ':' + hours + ':' + minutes)
}

function Navbar({ mobileOpen, handleSidebarToggle, brandText }) {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })

  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')

  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = useState(getCurrentDate(DT_TODAY))
  const [showModalConfirmLogout, setShowModalConfirmLogout] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.userInfo)
  const LogOutIcon = () => <img src={logoutImg} alt="Logout Icon" className="logout-icon" />
  const UserAvatar = () => <img src={userAvatar} alt="Avatar" className="avatar-icon" />
  const userName = JSON.parse(localStorage.getItem('userInfo'))
  const closeModalConfirmLogout = () => {
    setShowModalConfirmLogout(false)
  }

  // call action logout -> navigate to login page
  const handleLogout = async () => {
    await dispatch(logout())
    localStorage.removeItem('currentSession')
    history.push('/login')
  }

  // Countdown
  useEffect(() => {
    let intervalSetDate = setInterval(() => {
      setCurrentDate(getCurrentDate(DT_TODAY))
    }, 1000)

    return () => {
      clearInterval(intervalSetDate)
    }
  })

  return userInfo && userInfo.userInfo ? (
    <div className="nav-main">
      {showModalConfirmLogout && (
        <ModalConfirm
          title={t('commonComponent.navbar.msgLogout')}
          showModal={showModalConfirmLogout}
          closeModal={closeModalConfirmLogout}
          setShowModal={setShowModalConfirmLogout}
          handleFunction={handleLogout}
        />
      )}
      <div className="nav-wrapper">
        <div className="nav-title">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className={mobileOpen ? 'icon__toggle-show' : 'icon__toggle-hide'}
            onClick={handleSidebarToggle}
          >
            <MenuIcon />
          </IconButton>
          <div className="nav-pagename">{brandText}</div>
        </div>
        <div className="nav-description">
          <div className="nav-datetime nav-item">{moment(currentDate).locale('ja').format('YYYY年MM月DD日 HH:mm')}</div>
          <div className="nav-icon">
            <div className="avatar">
              <NavLink to={'/customer/my-page'}>
                <UserAvatar />
              </NavLink>
            </div>
          </div>
          <div className="nav-userinfo nav-item">
            <NavLink to={'/customer/my-page'}>
              <div>{userName}</div>
            </NavLink>
          </div>
          <div className="nav-dropdown nav-item">{/* <ExpandMoreIcon /> */}</div>
          <div className="nav-logout" onClick={() => setShowModalConfirmLogout(true)}>
            <LogOutIcon />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <MessageErrorDialog
        message={t('commonComponent.navbar.msgAuthen')}
        showMessageErrorDialog={() => {
          window.location.href = '/login'
        }}
      />
    </div>
  )
}

export default Navbar

import {
  CLEAR_COMPLIANCE,
  GET_COMPLIANCE_LIST_FAILED,
  GET_COMPLIANCE_LIST_REQUEST,
  GET_COMPLIANCE_LIST_SUCCESS,
  UPDATE_COMPLIANCE_FAILED,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'

const listCompliance = (state = { listCompliance: [] }, action) => {
  switch (action.type) {
    case GET_COMPLIANCE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_COMPLIANCE_LIST_REQUEST,
      }
    case GET_COMPLIANCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listCompliance: action.payload,
        type: GET_COMPLIANCE_LIST_SUCCESS,
      }
    case GET_COMPLIANCE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listCompliance: [],
        message: action.payload,
        type: GET_COMPLIANCE_LIST_FAILED,
      }
    default:
      return state
  }
}

const updateCompliance = (state = { listCompliance: {} }, action) => {
  switch (action.type) {
    case UPDATE_COMPLIANCE_REQUEST:
      return {
        ...state,
        loading: true,
        listCompliance: action.payload,
        type: UPDATE_COMPLIANCE_REQUEST,
      }
    case UPDATE_COMPLIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        listCompliance: { ...action.payload },
        type: UPDATE_COMPLIANCE_SUCCESS,
        message: 'success',
      }
    case UPDATE_COMPLIANCE_FAILED:
      return {
        ...state,
        loading: false,
        listCompliance: {},
        message: action.payload,
        type: UPDATE_COMPLIANCE_FAILED,
      }
    case CLEAR_COMPLIANCE:
      return { type: CLEAR_COMPLIANCE, loading: false, status: null, COMPLIANCE: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, COMPLIANCE: {}, message: '' }
  }
}

export { listCompliance, updateCompliance }

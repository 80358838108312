import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  toolbar: {
    height: '60px',
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-beetween',
  },
  actionContainer: {
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    marginBottom: '5px',
    backgroundColor: 'pink',
  },
  csvContainer: {
    height: '25px',
    alignItems: 'center',
    width: '20%',
    marginBottom: '5px',
    backgroundColor: 'yellow',
  },
  arrowContainer: {
    width: 'fit-content',
    height: '100%',
  },
  createContainer: {
    width: 'fit-content',
    height: '100%',
  },
  searchContainer: {
    height: '100%',
    width: '270px',
  },
  searchItem: {
    width: '100%',
    height: '100%',
  },
  iconRedirect: {
    width: '23px',
    height: '23px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },
}))

export default styles

import {
  GET_CODE_INFO_FAILED,
  GET_CODE_INFO_REQUEST,
  GET_CODE_INFO_SUCCESS,
} from 'constants/actionTypes/codeActionTypes'

function codeInfo(state = { code: {} }, action) {
  switch (action.type) {
    case GET_CODE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_CODE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload,
      }
    case GET_CODE_INFO_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
export { codeInfo }

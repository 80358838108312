import {
  GET_DELIVERY_DATA_DETAIL_LIST_FAILED,
  GET_DELIVERY_DATA_DETAIL_LIST_REQUEST,
  GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/deliveryDataDetailActionTypes'
const listDeliveryDataDetail = (state = { listDeliveryDataDetail: [] }, action) => {
  switch (action.type) {
    case GET_DELIVERY_DATA_DETAIL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_DELIVERY_DATA_DETAIL_LIST_REQUEST,
      }
    case GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listDeliveryDataDetail: action.payload,
        type: GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS,
      }
    case GET_DELIVERY_DATA_DETAIL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listDeliveryDataDetail: [],
        message: action.payload,
        type: GET_DELIVERY_DATA_DETAIL_LIST_FAILED,
      }
    default:
      return state
  }
}
export { listDeliveryDataDetail }

import './DeliveryData.css'

import { getDeliveryDataAction } from 'actions/deliveryDataAction'
import { caculateTableWidth, createListYmRegist, formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_DELIVERY_DATA_LIST_FAILED,
  GET_DELIVERY_DATA_LIST_REQUEST,
  GET_DELIVERY_DATA_LIST_SUCCESS,
} from 'constants/actionTypes/deliveryDataActionTypes'
import {
  BRANCH_CHARACTER,
  CHARACTER_WIDTH,
  MAX_CHARACTER,
  RIGHT_LEFT_PADDING,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import useWindowDimensions from './CustomHook'
import TableDeliveryData from './TableDeliveryData/TableDeliveryData'

function DeliveryData() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })

  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const ymRegistRef = useRef()
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listDeliveryDataReducer = useSelector((state) => state.listDeliveryData || {})
  const [listFilterData, setListFilterData] = useState([])
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  const currentYear = String(new Date(DT_TODAY).getUTCFullYear())
  const temp = String(new Date(DT_TODAY).getUTCMonth() + 1)
  const currentMonth = temp.length < 2 ? `0${temp}` : `${temp}`
  const currentYmRegist = currentYear + '-' + currentMonth

  const [filterItemYmRegist, setFilterItemYmRegist] = useState('0')

  const [listYmRegist, setListYmRegist] = useState([])

  const [listYmRegistOption, setListYmRegistOption] = useState([])

  const { tableDeliveryWidth } = useWindowDimensions()

  const getYmRegistLabel = () => {
    const listYmRegistFiltered = listYmRegistOption.filter((dtWeek) => dtWeek.value === String(filterItemYmRegist))
    const ymRegistLabel = listYmRegistFiltered[0]?.label
    return ymRegistLabel
  }

  useEffect(() => {
    ymRegistRef.current = getYmRegistLabel()
  }, [filterItemYmRegist])

  const handleFilterDate = async (e) => {
    e.preventDefault()
    setTimeout(() => {
      dispatch(getDeliveryDataAction({ date: ymRegistRef.current, cdCust: userInfo?.CD_CUST }))
    }, 1)
  }

  useEffect(() => {
    getDeliveryData()
    window.history.replaceState({}, document.title)
  }, [location])

  const getDeliveryData = React.useCallback(async () => {
    const { date, currentFilteredItem, listYmRegistCurrent, listYmRegistOptionCurrent } = location?.state || {}

    if (listYmRegistCurrent) {
      setListYmRegist(listYmRegistCurrent)
    }
    if (listYmRegistOptionCurrent) {
      setListYmRegistOption(listYmRegistOptionCurrent)
    }

    if (date && currentFilteredItem) {
      setFilterItemYmRegist(String(currentFilteredItem))
      dispatch(getDeliveryDataAction({ date: date, cdCust: userInfo?.CD_CUST }))
    } else {
      dispatch(getDeliveryDataAction({ cdCust: userInfo?.CD_CUST }))
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('beforeunload', reloadHandler)
    return () => {
      window.removeEventListener('beforeunload', reloadHandler)
    }
  }, [])
  const reloadHandler = (e) => {
    window.reload()
  }

  const getListFolderName = (listData = []) => {
    let listFolderNameArr = []
    for (const item of listData) {
      if (!listFolderNameArr.includes(item.NM_DISP_FLD)) {
        listFolderNameArr.push(item.NM_DISP_FLD)
      }
    }
    return listFolderNameArr
  }

  useEffect(() => {
    if (listDeliveryDataReducer.listDeliveryData) {
      if (listDeliveryDataReducer?.listDeliveryData?.listYmRegist) {
        const listYmRegist = listDeliveryDataReducer?.listDeliveryData?.listYmRegist
        setListYmRegist(listYmRegist)
        setListYmRegistOption(createListYmRegist(listYmRegist, currentYmRegist))
      }
      const listData = listDeliveryDataReducer?.listDeliveryData?.data

      let listFolderName = getListFolderName(listData)
      const dataFormat = arrayToMapHandler(listData, listFolderName)
      setListData(dataFormat?.data)
    }

    setLoading(listDeliveryDataReducer.loading)
    switch (listDeliveryDataReducer.type) {
      case GET_DELIVERY_DATA_LIST_REQUEST:
        break
      case GET_DELIVERY_DATA_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listDeliveryDataReducer.message)
        }
        break
      case GET_DELIVERY_DATA_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDeliveryDataReducer])

  useEffect(() => {
    if (listData) {
      setListFilterData(listData)
    }
  }, [listData])

  const createListColumnWidth = (listFolderName, branchList) => {
    let listColumnWidth = []
    listColumnWidth.push('62px')
    listColumnWidth = caculateBranchColumnWidth(listColumnWidth, branchList)
    listColumnWidth = caculateFolderNameWidth(listColumnWidth, listFolderName)
    let totalTableWidth = caculateTableWidth(listColumnWidth)
    if (totalTableWidth < TABLE_WIDTH) {
      listColumnWidth[listColumnWidth.length - 1] = ''
    }

    return listColumnWidth
  }

  const caculateBranchColumnWidth = (listColumnWidth = [], branchList) => {
    let maxcount = 0
    if (branchList) {
      branchList.forEach((branch) => {
        if (branch?.NM_BRANCH?.length > maxcount) {
          maxcount = branch.NM_BRANCH.length
        }
      })
    }
    if (maxcount < BRANCH_CHARACTER) {
      listColumnWidth.push(BRANCH_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
    } else {
      listColumnWidth.push(maxcount * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
    }
    return listColumnWidth
  }

  const caculateFolderNameWidth = (listColumnWidth = [], listFolderName) => {
    for (let index = 0; index < listFolderName?.length; index++) {
      if (listFolderName[index].length >= MAX_CHARACTER) {
        listColumnWidth.push(MAX_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      } else {
        listColumnWidth.push(listFolderName[index].length * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      }
    }
    return listColumnWidth
  }

  const createListCellHeader = (listFolderNameArr = []) => {
    const listCellHeader = []
    const branchHeaderCell = { columnName: '', commonStyle: 'paddingHeadDelivery', cellStyle: 'personTitleDelivery' }
    listCellHeader.push(branchHeaderCell)
    listCellHeader.push({
      columnName: t('deliveryData.lblNmBranch'),
      commonStyle: 'paddingHeadDelivery',
      cellStyle: 'personTitleDelivery',
    })
    for (let index = 0; index < listFolderNameArr?.length; index++) {
      let folderNameCellHeader = { columnName: '', commonStyle: 'paddingHeadDelivery', cellStyle: '' }
      const folderName = listFolderNameArr[index]
      folderNameCellHeader.columnName = folderName
      listCellHeader.push(folderNameCellHeader)
    }
    return listCellHeader
  }

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    {
      type: TOOLBAR_TYPE.YEAR_MONTH_CALENDAR,
      commonStyle: 'year-month-calendar-container',
      componentStyle: 'calendar-item',
    },
    // { type: TOOLBAR_TYPE.SEARCH_LEFT, commonStyle: 'searchLeftContainer', componentStyle: 'search-item' },
    // { type: TOOLBAR_TYPE.SORT, commonStyle: 'sort-input-container', componentStyle: 'sort-input' },
  ]

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      listData={listData}
      filterLabel={t('deliveryData.lblDisplayDate')}
      toolbarNoHeight={true}
      toolbarNoHeightPaddingTop={false}
      handleFilterDate={handleFilterDate}
      setFilterItemYmRegist={setFilterItemYmRegist}
      filterItemYmRegist={filterItemYmRegist}
      listYmRegistOption={listYmRegistOption}
      listYmRegist={listYmRegist}
    />
  )
  const msgDeleteConfirm = recordDelete ? t('headquarter.msgDeleteConfirm', { idUserValue: recordDelete.ID_USER }) : ''
  const redirectToDeliveryDataDetail = (data, cdCust, nmCust) => {
    const branch = {
      CD_BRANCH: data?.CD_BRANCH,
      NM_BRANCH: data?.NM_BRANCH,
    }
    const customer = {
      CD_CUST: cdCust,
      NM_CUST: nmCust,
    }
    const ymRegist = getYmRegistLabel()
    history.push({
      pathname: '/customer/delivery-data-detail',
      state: {
        branch: branch,
        customer: customer,
        date: ymRegist,
        listYmRegistOption: listYmRegistOption,
        filterItemYmRegist: filterItemYmRegist,
        listYmRegist: listYmRegist,
      },
    })
  }

  //return item of branch_list
  let handlerListBranchObjectInMap = (data, mapOfCust) => {
    //each branch has a list folders - (key-value) =(cd_branch ,[folder-name])
    let mapOfCdBranch = new Map()

    //each folder has a list files - (key-value) =(folder-name ,[file-name])
    // let mapOfListFile = new Map()
    let innerResponse = []
    for (const item of data) {
      let displayFolder = item.NM_DISP_FLD
      let cd_branch = item.CD_BRANCH
      if (!cd_branch) {
        continue
      }
      //check existed branch
      else if (mapOfCdBranch.has(cd_branch)) {
        let DISP_FLD = mapOfCdBranch.get(cd_branch).DISP_FLD
        if (DISP_FLD.filter((e) => e.NM_DISP_FLD === displayFolder).length === 0) {
          DISP_FLD.push({ NM_DISP_FLD: displayFolder, count: calculateSumOfNuCount(item, data) })
        } else {
          //check folder in branch
        }
      } else {
        //fist new folder
        let poolOfFolder = []

        poolOfFolder.push({ NM_DISP_FLD: displayFolder, count: calculateSumOfNuCount(item, data) })
        mapOfCdBranch.set(cd_branch, {
          CD_BRANCH: cd_branch,
          NM_BRANCH: item.NM_BRANCH || '',
          DISP_FLD: poolOfFolder,
        })
      }
    }

    for (const value of mapOfCdBranch.values()) {
      innerResponse.push(value)
    }
    return innerResponse
  }

  const calculateSumOfNuCount = (itemCurrent, data) => {
    let sum = 0
    data.forEach((item) => {
      if (
        itemCurrent.CD_CUST === item.CD_CUST &&
        itemCurrent.CD_BRANCH === item.CD_BRANCH &&
        itemCurrent.ID_FOLDER === item.ID_FOLDER
      ) {
        sum += item.NU_DL_COUNT
      }
    })
    return sum
  }

  const arrayToMapHandler = (data = [], listFolderFile) => {
    let mapOfCust = new Map()
    let response = {
      list_folder_name: listFolderFile,
      data: [],
    }
    for (const item of data) {
      if (!item.CD_CUST) {
        continue
      } else if (mapOfCust.has(item.CD_CUST)) {
        let prevList = mapOfCust.get(item.CD_CUST)
        prevList.list.push(item)
      } else {
        let list = []
        list.push(item)
        mapOfCust.set(item.CD_CUST, {
          NM_CUST: item.NM_CUST || '',
          CD_CUST: item.CD_CUST,
          list: list,
        })
      }
    }

    for (let [key, value] of mapOfCust.entries()) {
      let listOfBranch = handlerListBranchObjectInMap(
        value.list,
        mapOfCust,
        key
        // list_folder_name
      )
      response.data.push({
        CD_CUST: value.CD_CUST,
        NM_CUST: value.NM_CUST,
        BRANCH_LIST: listOfBranch,
      })
    }
    // response.list_folder_name = list_folder_name;
    return response
  }

  const getListDisplayFolderName = (data) => {
    let dataListFolder = data.BRANCH_LIST.reduce((prev, curr) => {
      for (const { NM_DISP_FLD } of curr.DISP_FLD) {
        prev.push(NM_DISP_FLD)
      }

      return prev
    }, [])

    let uniqueFolder = [...new Set([...dataListFolder])]
    return uniqueFolder.sort((a, b) => a.localeCompare(b, 'jp', { ignorePunctuation: true }))
  }

  const caculatePaddingLeftLastCell = (data) => {
    let dataListFolder = data?.reduce((prev, curr) => {
      for (const { NM_DISP_FLD } of curr.DISP_FLD) {
        prev.push(NM_DISP_FLD)
      }

      return prev
    }, [])

    let uniqueFolder = [...new Set([...dataListFolder])].sort((a, b) =>
      a.localeCompare(b, 'jp', { ignorePunctuation: true })
    )
    let CHARACTER_COUNT = uniqueFolder[uniqueFolder.length - 1].length
    if (CHARACTER_COUNT >= MAX_CHARACTER) {
      CHARACTER_COUNT = MAX_CHARACTER
    }

    let paddingLeft = (CHARACTER_WIDTH * CHARACTER_COUNT) / 2
    return paddingLeft
  }

  const isTableHaveScrollOnBottom = () => {
    let listColumnWidth,
      currentTableWidth,
      haveScroll = true
    if (listFilterData.length) {
      listFilterData.forEach((data) => {
        listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
        currentTableWidth = caculateTableWidth(listColumnWidth)
        if (currentTableWidth > tableDeliveryWidth) haveScroll = false
      })
      return haveScroll
    }
    return true
  }

  return (
    <div className="delivery-data__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('deliveryData.loading')} />}
      <ModalConfirm title={msgDeleteConfirm} showModal={Boolean(recordDelete)} setShowModal={setRecordDelete} />
      {loading ? (
        <ProgressDialog label={t('deliveryData.loading')} />
      ) : (
        <div className="delivery-data__container">
          <div className={`${isTableHaveScrollOnBottom() ? 'table-scroll' : ''}`}>
            <div>{toolbar}</div>
            <div className="list-table-container">
              {listFilterData &&
                listFilterData.map((data, index) => {
                  const listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
                  const currentTableWidth = caculateTableWidth(listColumnWidth)
                  return index === 0 ? (
                    <div
                      style={
                        currentTableWidth > tableDeliveryWidth
                          ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                          : {}
                      }
                    >
                      {/* Table */}
                      <TableDeliveryData
                        cdCust={data.CD_CUST}
                        nmCust={data.NM_CUST}
                        listColumnWidth={listColumnWidth}
                        // listCellHeader={listCellHeader}
                        // listFolderName={listFolderName}
                        listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                        listDataRender={data.BRANCH_LIST}
                        listFolderName={getListDisplayFolderName(data)}
                        hasHeaderLine={true}
                        toolbar={toolbar}
                        hasStartLineItem={true}
                        canViewDetail={true}
                        canUpdate={false}
                        canDelete={false}
                        handleRedirect={redirectToDeliveryDataDetail}
                        paddingLastCell={caculatePaddingLeftLastCell(data?.BRANCH_LIST)}
                      />
                      <div className="spacing"></div>
                    </div>
                  ) : (
                    <div
                      style={
                        currentTableWidth > tableDeliveryWidth
                          ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                          : {}
                      }
                    >
                      {/* Table */}
                      <TableDeliveryData
                        cdCust={data.CD_CUST}
                        nmCust={data.NM_CUST}
                        listColumnWidth={listColumnWidth}
                        // listCellHeader={listCellHeader}
                        // listFolderName={listFolderName}
                        listDataRender={data.BRANCH_LIST}
                        listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                        listFolderName={getListDisplayFolderName(data)}
                        hasHeaderLine={true}
                        hasStartLineItem={true}
                        canViewDetail={true}
                        canUpdate={false}
                        canDelete={false}
                        handleRedirect={redirectToDeliveryDataDetail}
                        paddingLastCell={caculatePaddingLeftLastCell(data?.BRANCH_LIST)}
                      />
                      <div className="spacing"></div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeliveryData

import './Portal.css'

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import getCodeInfo from 'actions/codeActions'
import { getPortalsAction } from 'actions/portalActions'
import {
  checkExistedFileInS3,
  formatDateWithPadding,
  getCurrentDate,
  getPreSignedUrlS3,
  // getFileFromS3,
  handleDownload,
} from 'commons/utilities'
import { parseDateTime } from 'commons/utilities'
import { MessageErrorDialog } from 'components/commons'
import ProgressDialog from 'components/commons/ProgressDialog/ProgressDialog'
import { CD_KEY, PORTAL_TYPES } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './PortalStyles'

function Portal({ type = '', modalClass, modalSelectedDate, onDateModalSelect }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = styles()
  const listPortalState = useSelector((state) => state.listPortals)
  const codeInfo = useSelector((state) => state.codeInfo.code[0])
  let { loading, listPortals } = listPortalState
  let listNotification = [],
    listTopic = [],
    listLink = []
  // const MAX_CHARACTER = 130
  const [downloadErrorMessage, setDownloadErrorMessage] = useState('')
  /**
   * Get Table Base On Mst_Code
   * @return {String}
   */
  const getTableTitle = (label) => {
    let title = ''
    if (codeInfo && Object.keys(codeInfo)?.length > 0) {
      switch (label) {
        case t('portal.lblNotificationType'):
          title = codeInfo.MP_ITEM01.NM_VALUE
          break
        case t('portal.lblTopic_title'):
          title = codeInfo.MP_ITEM02.NM_VALUE
          break
        case t('portal.lblLink_title'):
          title = codeInfo.MP_ITEM03.NM_VALUE
          break
        default:
          break
      }
      return title
    }
  }

  useEffect(() => {
    dispatch(getPortalsAction())
    dispatch(getCodeInfo({ CD_KEY: CD_KEY.PORTAL }))
  }, [dispatch])

  /**
   * To Japan Date Formart: YYYY/MM/DD
   * YYYY/MM/DD -> YYYY年MM月DD日
   * @return {String}
   */
  const japanDateFormat = (date) => {
    let moment = require('moment-timezone')
    if (date) {
      return moment(date).locale('ja').format('YYYY年MM月DD日')
    }
  }

  /**
   * Sort Data By Date
   * Ex order: 22/8/2021 -> 21/8/2021 -> 19/8/2021
   * @param {Array}
   * @return {Array}
   */
  const sortPortalByStartDate = (data) => {
    return data.sort((a, b) => b?.DT_START.localeCompare(a?.DT_START) || b?.DT_REGIST.localeCompare(a?.DT_REGIST))
  }

  const formatDate = (portals) => {
    return portals.map((portal) => ({
      ...portal,
      DT_START: formatDateWithPadding(new Date(portal.DT_START), 'yyyy-MM-dd'),
      DT_END: formatDateWithPadding(new Date(portal.DT_END), 'yyyy-MM-dd'),
    }))
  }

  if (listPortals.length > 0) {
    listNotification = formatDate(listPortals).filter(
      (portal) =>
        portal.KB_MESSAGE === PORTAL_TYPES.NOTICE &&
        portal.DT_START <= getCurrentDate() &&
        getCurrentDate() <= portal.DT_END
    )
    listTopic = formatDate(listPortals).filter(
      (portal) =>
        portal.KB_MESSAGE === PORTAL_TYPES.TOPIC &&
        portal.DT_START <= getCurrentDate() &&
        getCurrentDate() <= portal.DT_END
    )
    listLink = formatDate(listPortals).filter(
      (portal) =>
        portal.KB_MESSAGE === PORTAL_TYPES.LINK &&
        portal.DT_START <= getCurrentDate() &&
        getCurrentDate() <= portal.DT_END
    )
  }

  /**
   * Render table based on KB_MESSAGE type
   * @param {Array} portals
   * @param {String} label
   * @param {String} portalType (notification - topic - link)
   * @return {JSX}
   */
  const createPortalTable = (portals, label, portalType) => {
    if (portals.length > 0) {
      return (
        <Table className={classes.table} aria-label="simple table">
          <colgroup>
            <col className={classes.firstCol} />
            <col className={classes.secondCol} />
            <col className={classes.thirdCol} />
          </colgroup>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.cellTitle} colSpan={3}>
                {label}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{createPortalRow(portals, portalType)}</TableBody>
        </Table>
      )
    }
  }

  // const handleDownloadFile = async (file, data) => {
  //   const errorMessage = t('commonComponent.notExistFileInS3')
  //   const fileName = file
  //   const key = 'attempt-topics/' + data.KB_MESSAGE + '_' + parseDateTime(data.DT_REGIST) + '/' + file
  //   const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
  //   const fileObject = await getFileFromS3(bucketName, key)
  //   if (fileObject) {
  //     const { ContentType, Body } = fileObject
  //     const bufferArray = Body?.data
  //     const result = await handleDownload(bufferArray, fileName, ContentType)
  //     if (!result) {
  //       setDownloadErrorMessage(errorMessage)
  //     }
  //   }

  // }
  const handleDownloadFile = async (file, data) => {
    const errorMessage = t('commonComponent.notExistFileInS3')
    const fileName = file
    const key = 'attempt-topics/' + data.KB_MESSAGE + '_' + parseDateTime(data.DT_REGIST) + '/' + file
    const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
    const params = {
      bucket: bucketName,
      filePath: key,
    }
    const res = await checkExistedFileInS3(params)
    if (!res.data) {
      setDownloadErrorMessage(errorMessage)
    } else {
      setDownloadErrorMessage('')
      const filePath = await getPreSignedUrlS3(bucketName, key)
      const result = handleDownload(filePath, fileName)
      return result
    }
  }

  /**
   * Render row for table
   * @param {Array} portals
   * @param {String} portalType (notification - topic - link)
   * @return {JSX}
   */
  const createPortalRow = (portals, portalType) => {
    switch (portalType) {
      case PORTAL_TYPES.NOTICE:
        return sortPortalByStartDate(portals).map((portal, index) => {
          return (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.cellDate}>
                <div>{japanDateFormat(portal.DT_START)}</div>
              </TableCell>
              <TableCell colSpan={2} className={classes.customCellNoPading}>
                <div className={classes.titleBlock1}>
                  <div className={classes.childTitleBlock1}>
                    <div className={classes.cellContentTitle}>{portal.NM_TITLE}</div>
                  </div>
                  <div className={classes.childTitleBlock2}>
                    <span className={classes.personTitle}>{t('portal.lbl_person_sending')} : </span>
                    <span className={classes.personName}>{portal.NM_SENDER}</span>
                  </div>
                </div>
                <div>
                  <div className={classes.cellNoticeContentMessage}>{portal.NM_MESSAGE}</div>
                </div>
              </TableCell>
            </TableRow>
          )
        })
      case PORTAL_TYPES.TOPIC:
        return sortPortalByStartDate(portals).map((portal, index) => {
          return (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.cellDate}>{japanDateFormat(portal.DT_START)}</TableCell>
              <TableCell colSpan={2} className={classes.cellContent} style={{ paddingRight: '0px' }}>
                <div style={{ display: 'flex' }}>
                  <div className={classes.cellContentTitle}>
                    <div style={{ width: '450px', paddingRight: '10px' }}>{portal.NM_TITLE}</div>
                  </div>

                  <div>
                    <span className={classes.personTitle}>{t('portal.lbl_person_sending')} : </span>
                    <span className={classes.personName}>{portal.NM_SENDER}</span>
                  </div>
                </div>
                <Grid className={classes.cellContentMessage}>{portal.NM_MESSAGE}</Grid>
                <Grid container className={classes.fileAttachWrapper}>
                  {portal.hasOwnProperty('LT_ATTACH') &&
                    portal?.LT_ATTACH.map((file, index) => {
                      return (
                        <Grid key={index} className={classes.fileAttachContainer}>
                          <AttachFileIcon
                            className={type !== 'modal' ? `${classes.fileIcon} ${classes.fileAttachItem}` : modalClass}
                            fontSize="small"
                          />
                          <Link
                            onClick={() => handleDownloadFile(file, portal)}
                            to={'#'}
                            className={`${classes.link} ${classes.fileAttachItem}`}
                          >
                            {file.toString()}
                          </Link>
                        </Grid>
                      )
                    })}
                </Grid>
              </TableCell>
            </TableRow>
          )
        })
      case PORTAL_TYPES.LINK:
        return sortPortalByStartDate(portals).map((portal, index) => {
          return (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.cellLinkDate}>{japanDateFormat(portal.DT_START)}</TableCell>
              <TableCell className={classes.cellContent} colSpan={2}>
                <Link
                  to="#"
                  onClick={() => {
                    window.open(portal.NM_URL, '_blank')
                  }}
                  className={classes.url}
                >
                  {portal.NM_TITLE}
                </Link>
              </TableCell>
            </TableRow>
          )
        })
      default:
        return null
    }
  }

  const renderListPortal = () => {
    if (listPortals && listPortals.length > 0) {
      return (
        <Grid className={classes.portalContent}>
          <Grid className="portal-wrapper">
            <Grid item md={12}>
              <TableContainer className={classes.tableContainer}>
                {listNotification && listNotification.length > 0
                  ? createPortalTable(
                      listNotification,
                      getTableTitle(t('portal.lblNotificationType')),
                      PORTAL_TYPES.NOTICE
                    )
                  : null}
                {listTopic && listTopic.length > 0
                  ? createPortalTable(listTopic, getTableTitle(t('portal.lblTopic_title')), PORTAL_TYPES.TOPIC)
                  : null}
                {listLink && listLink.length > 0
                  ? createPortalTable(listLink, getTableTitle(t('portal.lblLink_title')), PORTAL_TYPES.LINK)
                  : null}
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return ''
  }

  return loading ? (
    // <ProgressDialog label={t('portal.msgReadData')} />
    <ProgressDialog label={t('portal.loading')} />
  ) : (
    <div>
      {downloadErrorMessage && (
        <MessageErrorDialog message={downloadErrorMessage} showMessageErrorDialog={setDownloadErrorMessage} />
      )}
      {renderListPortal()}
    </div>
  )
}

export default Portal

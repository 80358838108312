import { checkEmail } from 'commons/utilities'

const forgotPasswordValidate = (userId, email, i18n) => {
  const messages = {}
  if (!userId.trim().length) {
    messages.idUser = i18n.t('validate.forgotPassword.msgValidateId')
  }
  // validate email
  if (!checkEmail(email)) {
    messages.email = i18n.t('validate.forgotPassword.msgValidateEmail')
  }
  return messages
}

export default forgotPasswordValidate

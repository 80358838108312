import './InfomationSharpCornerTop.css'

import React from 'react'

function InformationSharpCornerTop({ icon, message }) {
  return (
    <div className="info-container">
      <span className="icon-container">{icon}</span>
      <div className="infoText-container">
        <div className="triangle"></div>
        <div className="triangle-hiden"></div>
        <div className="infoText">{message}</div>
      </div>
    </div>
  )
}

export default InformationSharpCornerTop

import './FormInputs.css'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { removeAllSpace } from 'commons/utilities'
import { KEY_CODE, SCREEN_NAME } from 'constants/constant'
import React from 'react'

function FormInputs({
  label,
  icon,
  defaultIcon = true,
  type,
  canSpacing = true,
  inputProps,
  setValue,
  autoComplete = 'off',
  errorPosition = '50px',
  onlyNumber = false,
  inputCustomerRef,
  tabIndex,
  setOnFocusCustomerId,
  minutesLabel,
  name = '',
  screen = '',
  numberMaxlength = '',
  errorTopPosition = '7%',
  ...rest
}) {
  const useStyles = makeStyles((theme) => ({
    customPhoneMypage: {
      '& .MuiTextField-root .MuiInput-formControl': {
        width: '120px',
      },
      '& .MuiInputLabel-shrink': {
        transformOrigin: '0 40px',
        transform: 'translate(0, 1.5px) scale(0.833) !important',
      },
    },
    customClassPassword: {
      '& label#standard-basic-label': {
        paddingLeft: 0,
      },
      '& .MuiTextField-root .MuiInput-formControl': {
        marginTop: '18.760px',
        width: '120px',
      },
      '& .MuiInputLabel-shrink': {
        transformOrigin: '0 40px',
        transform: 'translate(0, 1.5px) scale(0.769) !important',
      },
      '& .wrap-icon': {
        width: 'unset',
        position: 'relative',
        top: '4px',
        margin: 0,
        paddingTop: 10,
        paddingRight: 15,
      },
    },
    root: {
      '& .MuiTextField-root': {
        position: 'relative',
        '& .MuiInputLabel-formControl.Mui-required': {
          '&::after': {
            content: '"*"',
            background: '#fff',
            color: 'red',
            fontSize: 20,
            margin: 3,
            marginRight: 20,
            position: 'absolute',
            width: 'max-content',
            top: -5,
            left: 'calc(100% + 5px)',
          },
          '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
            display: 'none',
          },
        },
        '& .MuiFormHelperText-root.Mui-error': {
          width: 'max-content',
          fontSize: 10,
          position: 'absolute',
          top: errorTopPosition,
          left: errorPosition,
        },
      },
    },
  }))
  const classes = useStyles()
  const onChange = (event) => {
    if (onlyNumber) {
      const re = /^[0-9\b]+$/
      if (event.target.value === '' || re.test(event.target.value)) {
        setValue(event.target.value)
      }
      return
    }
    if (!canSpacing) {
      event.target.value = removeAllSpace(event.target.value)
    }
    if (numberMaxlength) {
      // Regex remove all character other than 0-9
      let value = event.target.value.toString().replace(/[^0-9]+/g, '')
      if (value.length > numberMaxlength) {
        setValue(value.substring(0, numberMaxlength))
        return
      } else {
        setValue(value)
        return
      }
    }

    setValue(event.target.value)
  }
  const handleBLur = () => {
    if (typeof setOnFocusCustomerId === 'function') {
      setOnFocusCustomerId(false)
    }
  }
  const handleOnkeyPress = (event) => {
    if (!canSpacing && event.keyCode === KEY_CODE.SPACE) {
      event.target.value = removeAllSpace(event.target.value)
    }
  }
  return (
    <div
      className={`${classes.root} wrap ${
        name === 'NU_TEL' && screen === SCREEN_NAME.CHANGE_PASSWORD
          ? classes.customClassPassword
          : name === 'NU_TEL' && screen === SCREEN_NAME.MYPAGE
          ? classes.customPhoneMypage
          : ''
      }`}
    >
      {!defaultIcon ? <></> : <div className="wrap-icon">{icon}</div>}
      <TextField
        label={label}
        type={type}
        inputProps={inputProps}
        autoComplete={autoComplete}
        onChange={onChange}
        onKeyPress={handleOnkeyPress}
        spellCheck="false"
        inputRef={inputCustomerRef}
        onBlur={handleBLur}
        {...rest}
      />
      {minutesLabel}
    </div>
  )
}

export default FormInputs

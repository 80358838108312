import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'
import ja from './ja'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_LOCALE,
    fallbackLng: process.env.REACT_APP_LOCALE,
    interpolation: {
      prefix: '{{',
      suffix: '}}',
    },
  })

export default i18n

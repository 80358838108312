import Auth from '@aws-amplify/auth'
import { showAlertAuth } from 'actions/userActions'
import axios from 'axios'
import { store } from 'store'
const { dispatch } = store
let loginAlert = false

// https://github.com/axios/axios#request-config
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENPOINT,
})

// Request interceptor for API calls
// https://github.com/axios/axios#interceptors
instance.interceptors.request.use(
  async (config) => {
    let ss = await Auth.currentSession().catch((e) => {
      if (!loginAlert) {
        loginAlert = true
        dispatch(showAlertAuth())
        return
      }
    })
    if (!ss) {
      localStorage.setItem('currentSession', JSON.stringify('timeOut'))
    }
    const token = ss ? ss?.getIdToken()?.getJwtToken() : ''
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: token || '',
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (typeof error.response === 'undefined') {
      dispatch(showAlertAuth())
      return
    }
    if (error.response && error.response.status === 401) {
      const ss = await Auth.currentSession().catch((e) => {
        if (!loginAlert) {
          loginAlert = true
          dispatch(showAlertAuth())
        }
      })

      const token = ss ? ss?.getIdToken()?.getJwtToken() : ''

      error.config.headers['Authorization'] = token
      error.config.headers['Content-Type'] = 'application/json'
      error.config.baseURL = undefined

      return instance.request(error.config)
    }

    return Promise.reject(error)
  }
)

export default instance

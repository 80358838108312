import {
  CLEAR_ALLERGEN,
  CREATE_ALLERGEN_FAILED,
  CREATE_ALLERGEN_REQUEST,
  CREATE_ALLERGEN_SUCCESS,
  DELETE_ALLERGEN_FAILED,
  DELETE_ALLERGEN_REQUEST,
  DELETE_ALLERGEN_SUCCESS,
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
  UPDATE_ALLERGEN_FAILED,
  UPDATE_ALLERGEN_REQUEST,
  UPDATE_ALLERGEN_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'

const listAllergen = (state = { listAllergen: [] }, action) => {
  switch (action.type) {
    case GET_ALLERGEN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_ALLERGEN_LIST_REQUEST,
      }
    case GET_ALLERGEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listAllergen: action.payload,
        type: GET_ALLERGEN_LIST_SUCCESS,
      }
    case GET_ALLERGEN_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listAllergen: [],
        message: action.payload,
        type: GET_ALLERGEN_LIST_FAILED,
      }
    default:
      return state
  }
}

const createAllergen = (state = { allergen: {} }, action) => {
  switch (action.type) {
    case CREATE_ALLERGEN_REQUEST:
      return {
        ...state,
        loading: true,
        allergen: action.payload,
        type: CREATE_ALLERGEN_REQUEST,
      }
    case CREATE_ALLERGEN_SUCCESS:
      return {
        ...state,
        loading: false,
        allergen: { ...action.payload },
        type: CREATE_ALLERGEN_SUCCESS,
        message: 'success',
      }
    case CREATE_ALLERGEN_FAILED:
      return {
        ...state,
        loading: false,
        allergen: {},
        message: action.payload,
        type: CREATE_ALLERGEN_FAILED,
      }
    case CLEAR_ALLERGEN:
      return { type: CLEAR_ALLERGEN, loading: false, status: null, allergen: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, allergen: {}, message: '' }
  }
}

const updateAllergen = (state = { allergen: {} }, action) => {
  switch (action.type) {
    case UPDATE_ALLERGEN_REQUEST:
      return {
        ...state,
        loading: true,
        allergen: action.payload,
        type: UPDATE_ALLERGEN_REQUEST,
      }
    case UPDATE_ALLERGEN_SUCCESS:
      return {
        ...state,
        loading: false,
        allergen: { ...action.payload },
        type: UPDATE_ALLERGEN_SUCCESS,
        message: 'success',
      }
    case UPDATE_ALLERGEN_FAILED:
      return {
        ...state,
        loading: false,
        allergen: {},
        message: action.payload,
        type: UPDATE_ALLERGEN_FAILED,
      }
    case CLEAR_ALLERGEN:
      return { type: CLEAR_ALLERGEN, loading: false, status: null, allergen: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, allergen: {}, message: '' }
  }
}

const deleteAllergen = (state = { allergen: {} }, action) => {
  switch (action.type) {
    case DELETE_ALLERGEN_REQUEST:
      return {
        ...state,
        loading: true,
        allergen: action.payload,
        type: DELETE_ALLERGEN_REQUEST,
      }
    case DELETE_ALLERGEN_SUCCESS:
      return {
        ...state,
        loading: false,
        allergen: { ...action.payload },
        type: DELETE_ALLERGEN_SUCCESS,
        message: 'success',
      }
    case DELETE_ALLERGEN_FAILED:
      return {
        ...state,
        loading: false,
        allergen: {},
        message: action.payload,
        type: DELETE_ALLERGEN_FAILED,
      }
    default:
      return { ...state, loading: false, status: null, allergen: {}, message: '' }
  }
}

export { createAllergen, deleteAllergen, listAllergen, updateAllergen }

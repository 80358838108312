import {
  CLEAR_MY_PAGE,
  GET_MY_PAGE_FAILED,
  GET_MY_PAGE_REQUEST,
  GET_MY_PAGE_SUCCESS,
  UPDATE_MY_PAGE_FAILED,
  UPDATE_MY_PAGE_REQUEST,
  UPDATE_MY_PAGE_SUCCESS,
} from 'constants/actionTypes/myPageActionTypes'

const updateMyPage = (state = { hquser: {} }, action) => {
  switch (action.type) {
    case UPDATE_MY_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        hquser: action.payload,
        type: UPDATE_MY_PAGE_REQUEST,
      }
    case UPDATE_MY_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        hquser: { ...action.payload },
        type: UPDATE_MY_PAGE_SUCCESS,
        message: 'success',
      }
    case UPDATE_MY_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        hquser: {},
        message: action.payload,
        type: UPDATE_MY_PAGE_FAILED,
      }
    case CLEAR_MY_PAGE:
      return { type: CLEAR_MY_PAGE, loading: false, status: null, hquser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, hquser: {}, message: '' }
  }
}

const getMyPage = (state = { hquser: {} }, action) => {
  switch (action.type) {
    case GET_MY_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        hquser: action.payload,
        type: GET_MY_PAGE_REQUEST,
      }
    case GET_MY_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        hquser: { ...action.payload },
        type: GET_MY_PAGE_SUCCESS,
        message: 'success',
      }
    case GET_MY_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        hquser: {},
        message: action.payload,
        type: GET_MY_PAGE_FAILED,
      }
    case CLEAR_MY_PAGE:
      return { type: CLEAR_MY_PAGE, loading: false, status: null, hquser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, hquser: {}, message: '' }
  }
}

export { getMyPage, updateMyPage }

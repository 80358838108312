import './TableDataDownload.css'

import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { CheckSharp } from '@material-ui/icons'
// import axios from 'axios'
import {
  checkExistedFileInS3,
  // convertArrayBufferToBase64,
  formatNumberData,
  getPreSignedUrlS3,
  // getFileFromS3,
  handleDownloadSingleFile,
  handleDownloadSingleFileDataDownload,
} from 'commons/utilities'
import { CustomCheckbox, Image } from 'components/commons'
import FilePreviewer from 'components/commons/FilePreviewer/FilePreviewer'
import {
  ACTION_FORM,
  CELL_TYPE_DATA_TRANSFER,
  FILE_FORMAT,
  ID_CELL_TYPE,
  // S3_BUCKET_URL,
  // S3_NIGHT_FUNCTION_BUCKET_URL,
  // S3_RCV,
  // S3_TEMPLATE,
  SPECIAL_CELL_TYPE,
  UNIT,
} from 'constants/constant'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IMAGES } from '../../../assets'
import styles from './TableDataDownloadStyle'

function TableDownloadStatus({
  listColumnWidth = [],
  listCellHeader = [],
  listCellBody = [],
  listFolderName = [],
  listDataRender = [],
  handleUpdate,
  handleDelete,
  canDownloadFile = false,
  canUpdate = true,
  checkDataWithItem,
  canDelete = true,
  hasFirstCell = true,
  hasEndCell = false,
  idProperty = '',
  tableWidth,
  toolbar,
  hasHeaderLine = false,
  hasRecordCounter = false,
  hasStartLineItem = false,
  startLineItem,
  handleNavigate,
  selectedDate,
  isDateSelected,
  undownloaded,
  cdCust,
  listData,
  setListData,
  handleSelectAllInTable,
  currentFolder,
  selectAllInTable,
  setOnChoosenFolderList,
  updateDataDownload,
  setDownloadErrorMessage,
  setPreviewErrorMessage,
  setNotExistFileInS3Error,
}) {
  const { t } = useTranslation()
  const classes = styles(listColumnWidth)
  const [previewFile, setPreviewFile] = useState(false)
  const [filePreview, setFilePreview] = useState('')
  const [fileType, setFileType] = useState('')
  const [file, setFile] = useState()

  const handleDownloadFile = async (folder, file, senddate) => {
    const fileName = file
    const errorMessage = t('commonComponent.notExistFileInS3')
    const filePath = folder + file
    const sdate = senddate
    const bucketName = process.env.REACT_APP_S3_DATA_BUCKET

    // try {
    //   const fileObject = await getFileFromS3(bucketName, filePath)

    //   const { ContentType, Body } = fileObject
    //   if (ContentType !== undefined && Body !== undefined) {
    //     const bufferArray = Body?.data
    //     const base64File = convertArrayBufferToBase64(bufferArray)
    //     const url = `data:${ContentType};base64,` + base64File
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.download = fileName
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)
    //     updateDataDownload(file)
    //   } else {
    //     setDownloadErrorMessage(errorMessage)
    //   }
    // } catch (e) {
    //   setDownloadErrorMessage(errorMessage)
    // }
    const params = {
      bucket: bucketName,
      filePath: filePath,
      dtRegist: sdate,
    }

    const res = await checkExistedFileInS3(params)
    if (!res.data) {
      setNotExistFileInS3Error(errorMessage)
    } else {
      setNotExistFileInS3Error('')
      const fileUrl = await getPreSignedUrlS3(bucketName, filePath)
      handleDownloadSingleFileDataDownload(fileUrl, fileName, updateDataDownload(file, senddate))
    }
  }

  const sortListDataRender = (listDataRender) => {
    let sortedList = listDataRender
    if (sortedList?.length > 0) {
      sortedList.sort((firstItem, secondItem) =>
        // secondItem?.NM_FILE_DSP.localeCompare(firstItem?.NM_FILE_DSP, 'jp', { ignorePunctuation: true })
        {
          let firstItemDate = new Date(firstItem?.DT_REGIST + ' ' + firstItem?.TM_REGIST)
          let secondItemDate = new Date(secondItem?.DT_REGIST + ' ' + secondItem?.TM_REGIST)
          return secondItemDate - firstItemDate
        }
      )
    }
    return sortedList
  }

  const handlePreviewFile = async (folder, file, senddate) => {
    const errorMessage = t('commonComponent.notExistFileInS3')
    const bucket = process.env.REACT_APP_S3_DATA_BUCKET
    const key = folder + file
    const sdate = senddate
    const params = {
      bucket: bucket,
      filePath: key,
      dtRegist: sdate,
    }
    const res = await checkExistedFileInS3(params)
    if (res.data) {
      await renderPreviewFile(bucket, key, file)
    } else {
      setPreviewErrorMessage(errorMessage)
    }
  }

  const renderPreviewFile = async (bucket, key, file) => {
    let filePreview, fileType
    if (!file) return
    if (file instanceof File) {
      filePreview = window.URL.createObjectURL(file)
      fileType = file?.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : null
      setFilePreview(filePreview)
      setFileType(fileType)
    } else {
      filePreview = await getPreSignedUrlS3(bucket, key)
      setFilePreview(filePreview)
      fileType = file.substr(file.lastIndexOf('.') + 1)
      setFileType(fileType)
    }
    if (filePreview && fileType) {
      setFile(file)
      setPreviewFile(true)
    } else {
      setPreviewFile(false)
    }
  }

  const handleOnFileSelected = (data, e) => {
    const resultData = [...listData]

    resultData.forEach((el) => {
      let index = el.list_file.findIndex((value) => value.KY_PARTS + value.DT_REGIST === data.KY_PARTS + data.DT_REGIST)
      if (index >= 0) {
        el.list_file[index].IS_SELECTED = (e + 0).toString()
      }
    })

    // setListCheckboxData(resultData)
    checkDataWithItem(resultData)
    setListData(resultData)
  }

  const renderCellHeader = (cellHeader) => {
    if (typeof cellHeader !== 'undefined') {
      const headerName = t(cellHeader.columnName)
      if (headerName.length > 10) {
        let substring = headerName.substring(0, 10).concat('...')
        return <div className={classes.overFlowText}>{substring}</div>
      }
    }
    return <div className={classes.overFlowText}>{t(cellHeader.columnName)}</div>
  }

  /**
   * Get single cell in header of table
   * @return {JSX}
   */
  const getCellHeader = (cellHeader = {}, index = 0) => {
    let component = (
      <TableCell className={`${classes[cellHeader.commonStyle]} ${classes[cellHeader.cellStyle]}`} key={index}>
        <div className={`${classes.cellHeaderContainer} `}>{renderCellHeader(cellHeader)}</div>
      </TableCell>
    )
    if (cellHeader.hasOwnProperty('specialType')) {
      switch (cellHeader.specialType) {
        /* SORTED START */
        case SPECIAL_CELL_TYPE.CHECK_BOX:
          component = (
            <TableCell className={`${classes.titleHead}`} key={index}>
              <div className={`${classes.cellHeaderContainer} `}>
                <CustomCheckbox
                  // checked={data.IS_SELECTED}
                  value={Boolean(Number(currentFolder.IS_SELECTED_ALL))}
                  onChange={(e) => handleSelectAllInTable(listData, startLineItem)}
                  className="checkbox-download"
                />
              </div>
            </TableCell>
          )
          return component
        default:
          return <></>
      }
    }
    return component
  }
  // const getFileName = (path) => {
  //   const index = path.toString().lastIndexOf('/')
  //   const fileName = path.toString().substring(index + 1, path.toString().length)
  //   return fileName
  // }
  const renderCell = (cellName, data) => {
    if (typeof cellName !== 'undefined') {
      const filterResult = ID_CELL_TYPE.filter((cell) => cell.includes(cellName))
      if (filterResult.length >= 1) {
        if (data[cellName].length > 10) {
          let substringID = data[cellName].substring(0, 10).concat('...')
          return <div className={classes.overFlowText}>{substringID}</div>
        }
      }

      if (cellName === CELL_TYPE_DATA_TRANSFER.KB_FL_FORMAT) {
        switch (data.KB_FL_FORMAT) {
          case FILE_FORMAT.TEXT:
            return UNIT.TEXT
          case FILE_FORMAT.PDF:
            return UNIT.PDF
          default:
            return <></>
        }
      }
      if (cellName === CELL_TYPE_DATA_TRANSFER.EXCEP_NUMBER) {
        return String(data[cellName]) === '0' ? <></> : <div>{data[cellName] + UNIT.QUANTITY}</div>
      }
      if (cellName === CELL_TYPE_DATA_TRANSFER.KB_DELETE) {
        return Boolean(Number(`${data[cellName]}`)) ? (
          <CheckSharp className={`${classes.iconBlur} ${classes.iconSizeDistribution} `} />
        ) : null
      }
    }
    return <div className={classes.overFlowText}>{data[`${cellName}`]}</div>
  }

  /**
   * Get single cell in body row of table
   * @return {JSX}
   */

  const getCellBody = (body = {}, index = 0, data = {}) => {
    let component = (
      <TableCell className={`${classes[body.cellStyle]}`} key={index}>
        <Grid className={`${classes[body.gridStyle]}`}>{renderCell(body.keyValue, data)}</Grid>
      </TableCell>
    )
    if (body.hasOwnProperty('specialType')) {
      switch (body.specialType) {
        /* HH:MM */
        case SPECIAL_CELL_TYPE.HHMM:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>{data.TM_RCV.substring(0, 5)}</Grid>
            </TableCell>
          )
          return component
        /* SIZE */
        case SPECIAL_CELL_TYPE.NU_SIZE:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>{formatNumberData(data.NU_SIZE.toFixed(1))}</div>
                <span>{'KB'}</span>
              </Grid>
            </TableCell>
          )
          return component
        /* DT_REGIST */
        case SPECIAL_CELL_TYPE.DT_REGIST:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>
                  {t('dataDownload.dtRegist', {
                    dtRegist: data?.DT_REGIST,
                    tmRegist: data?.TM_REGIST.substring(0, 5),
                  })}
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* DT_DL */
        case SPECIAL_CELL_TYPE.DT_DL:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>{data?.DT_DL?.substring(0, 16)}</div>
              </Grid>
            </TableCell>
          )
          return component
        /* DT_REGIST */
        case SPECIAL_CELL_TYPE.KB_DL:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>
                  {data?.KB_DL === '0' ? `${t('dataDownload.undownloaded')}` : `${t('dataDownload.downloaded')}`}
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* CHECKBOX */
        case SPECIAL_CELL_TYPE.CHECK_BOX:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div>
                  <CustomCheckbox
                    // checked={data.IS_SELECTED}
                    value={Boolean(Number(data.IS_SELECTED))}
                    onChange={(e) => handleOnFileSelected(data, e)}
                    className="checkbox-download"
                  />
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* PREVIEW */
        case SPECIAL_CELL_TYPE.PREVIEW:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className="">
                  <IconButton size="small">
                    <Image
                      src={IMAGES.ICON.PREVIEW_FILE}
                      style={{ width: 14 }}
                      onClick={(e) => {
                        e.preventDefault()
                        handlePreviewFile(data.ID_FOLDER, data.ID_FILE, data.DT_REGIST)
                      }}
                    ></Image>
                  </IconButton>
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* DOWNLOAD */
        case SPECIAL_CELL_TYPE.DOWNLOAD:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div>
                  <IconButton size="small">
                    <Image
                      src={IMAGES.ICON.DOWNLOAD_ICON}
                      style={{ width: 14 }}
                      onClick={() => handleDownloadFile(data.ID_FOLDER, data.ID_FILE, data.DT_REGIST)}
                    ></Image>
                  </IconButton>
                </div>
              </Grid>
            </TableCell>
          )
          return component
        default:
          return <></>
      }
    }
    return component
  }
  const renderDownloadIcon = (canDownloadFile, data, idProperty) => {
    if (canDownloadFile) {
      return (
        <Image
          src={IMAGES.ICON.DOWNLOAD_ICON}
          className={classes.iconAction}
          onClick={() => {
            if (idProperty === 'ID_TEMP') {
              const templateFileUrl =
                process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_TEMPLATE + data.ID_TEMP
              handleDownloadSingleFile(templateFileUrl)
            }
            if (idProperty === 'ID_RCV_DATA') {
              const receptionDataFileUrl =
                process.env.REACT_APP_S3_NIGHT_FUNCTION_BUCKET_URL + process.env.REACT_APP_S3_RCV + data.ID_RCV_DATA
              handleDownloadSingleFile(receptionDataFileUrl)
            }
          }}
        />
      )
    } else {
      return
    }
  }

  return (
    <div className="table-container-fit-content">
      <Grid item md={12}>
        <TableContainer>
          <div>
            <div className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}>
              {hasStartLineItem && <span className={classes.startLineItem}>{startLineItem}</span>}
              {hasRecordCounter && (
                <span
                  className={tableWidth === 'SMALL' ? classes.recordCounterSmallWidth : classes.recordCounterFullWidth}
                >
                  {t('dataDownload.counter', {
                    count: listDataRender?.length,
                  })}
                </span>
              )}
              {hasHeaderLine && (
                <div className={classes.headerLine}>
                  <hr className={tableWidth === 'SMALL' ? classes.smallWidthHeaderLine : classes.fullWidthHeaderLine} />
                </div>
              )}
            </div>
            <Table
              aria-label="common table"
              className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}
            >
              {/* Col Group */}
              <colgroup>
                {listColumnWidth.map((col, index) => (
                  <col style={col !== '' ? { width: col } : {}} key={index}></col>
                ))}
              </colgroup>
              {/* Header */}
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {/* Header Cell */}
                  {listCellHeader && listCellHeader.map((cellHeader, index) => getCellHeader(cellHeader, index))}
                </TableRow>
              </TableHead>
              {/* Body */}
              <TableBody>
                {/* Body Row */}
                {sortListDataRender(listDataRender)?.map((data, index) => {
                  return (
                    <TableRow key={index} className={index % 2 === 0 ? '' : classes.tableRow} style={{ height: 50 }}>
                      {/* Body Cell */}
                      {hasFirstCell && (
                        <TableCell className={classes.iconUpdate}>
                          <Grid className={classes.iconContainer}>
                            {canUpdate && (
                              <Image
                                src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                className={classes.iconAction}
                              />
                            )}
                            {renderDownloadIcon(canDownloadFile, data, idProperty)}
                            {canDelete && (
                              <Image
                                src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                className={classes.iconAction}
                                onClick={() => {
                                  handleDelete(data)
                                }}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                      {listCellBody.map((body, indexBody) => getCellBody(body, indexBody, data))}
                      {hasEndCell && (
                        <TableCell className={classes.iconUpdate}>
                          <Grid className={classes.iconContainer}>
                            {canUpdate && (
                              <Image
                                src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                className={classes.iconAction}
                              />
                            )}
                            {renderDownloadIcon(canDownloadFile, data, idProperty)}
                            {canDelete && (
                              <Image
                                src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                className={classes.iconAction}
                                onClick={() => {
                                  handleDelete(data)
                                }}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Grid>
      {previewFile && (
        <FilePreviewer
          file={filePreview}
          type={fileType}
          preview={previewFile}
          setPreview={setPreviewFile}
          filename={file}
        />
      )}
    </div>
  )
}

export default TableDownloadStatus

const userLoginValidate = (userId, password, i18n) => {
  let error = ''
  if (!userId.trim().length) {
    error = i18n.t('login.msgUserIdRequired')
    return {
      validate: false,
      error,
    }
  }
  if (!password.trim().length) {
    error = i18n.t('login.msgPasswordRequired')
    return {
      validate: false,
      error,
    }
  }
  return {
    validate: true,
    error,
  }
}

export default userLoginValidate

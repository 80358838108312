export const GET_PORTAL_LIST_REQUEST = 'GET_PORTAL_LIST_REQUEST'
export const GET_PORTAL_LIST_SUCCESS = 'GET_PORTAL_LIST_SUCCESS'
export const GET_PORTAL_LIST_FAILED = 'GET_PORTAL_LIST_FAILED'

export const CREATE_PORTAL_REQUEST = 'CREATE_PORTAL_REQUEST'
export const CREATE_PORTAL_SUCCESS = 'CREATE_PORTAL_SUCCESS'
export const CREATE_PORTAL_FAILED = 'CREATE_PORTAL_FAILED'

export const UPDATE_PORTAL_REQUEST = 'UPDATE_PORTAL_REQUEST'
export const UPDATE_PORTAL_SUCCESS = 'UPDATE_PORTAL_SUCCESS'
export const UPDATE_PORTAL_FAILED = 'UPDATE_PORTAL_FAILED'

export const DELETE_PORTAL_REQUEST = 'DELETE_PORTAL_REQUEST'
export const DELETE_PORTAL_SUCCESS = 'DELETE_PORTAL_SUCCESS'
export const DELETE_PORTAL_FAILED = 'DELETE_PORTAL_FAILED'

export const RESET_PORTAL = 'RESET_PORTAL'

import axios from 'commons/baseUrl'
import {
  CHANGE_PASS_FAILED,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
} from 'constants/actionTypes/changePassActionTypes'
import { API_CHANGE_PASSWORD } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const changePass =
  ({ password, newPassword, confirmPassword, userName, cdCust, nuTel, userInfo }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASS_REQUEST })
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const params = {
        userName: userName,
        oldPassword: password,
        newPassword: newPassword,
        reEnterPassword: confirmPassword,
        cdCust: cdCust,
        nuTel: nuTel,
        userInfo: userInfo,
      }

      const res = await axios.post(API_CHANGE_PASSWORD, JSON.stringify(params))
      if (!res.data || res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST || res.data.STS === RESPONSE_TYPE.ERROR) {
        dispatch({ type: CHANGE_PASS_FAILED, payload: res.data.message })
      }
      if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
        dispatch({ type: CHANGE_PASS_SUCCESS, payload: res.data.message })
      }
    } catch (error) {
      dispatch({ type: CHANGE_PASS_FAILED, payload: error.data.message })
    }
  }

const resetState = () => async (dispatch) => {
  dispatch({ type: CHANGE_PASS_RESET })
}
export { changePass, resetState }

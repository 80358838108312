import React from 'react'

function Card({ title, category, content }) {
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">{title}</h4>
        <p className="category">{category}</p>
      </div>
      <div className="content">{content}</div>
    </div>
  )
}

export default Card

import axios from 'commons/baseUrl'
import {
  CLEAR_MY_PAGE,
  GET_MY_PAGE_FAILED,
  GET_MY_PAGE_REQUEST,
  GET_MY_PAGE_SUCCESS,
  UPDATE_MY_PAGE_FAILED,
  UPDATE_MY_PAGE_REQUEST,
  UPDATE_MY_PAGE_SUCCESS,
} from 'constants/actionTypes/myPageActionTypes'
import { API_GET_USER_DETAIL, API_UPDATE_MY_PAGE } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const updateMyPageAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MY_PAGE_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_MY_PAGE, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_MY_PAGE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_MY_PAGE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_MY_PAGE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_MY_PAGE_FAILED, payload: err.message })
  }
}

const getMyPageAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_MY_PAGE_REQUEST, payload: data })
    const res = await axios.post(API_GET_USER_DETAIL, JSON.stringify(data))
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_MY_PAGE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: GET_MY_PAGE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      localStorage.setItem('userInfo', JSON.stringify(res.data.data?.NM_USER))
      dispatch({ type: GET_MY_PAGE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_MY_PAGE_FAILED, payload: err.message })
  }
}

const clearMyPage = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_MY_PAGE, payload: '' })
}

export { clearMyPage, getMyPageAction, updateMyPageAction }

import './Information.css'

import React from 'react'

function Information({ icon, message, screen = '' }) {
  return (
    <div className={`${screen === 'mypage' ? 'custom-background-infor' : ''} info-container`}>
      <span className="icon-container">{icon}</span>
      <div className="infoText-container">
        <div className="infoText">
          <div>{message}</div>
        </div>
      </div>
    </div>
  )
}

export default Information

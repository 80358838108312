import axios from 'commons/baseUrl'
import { formatDateWithPadding } from 'commons/utilities'
import {
  GET_SYS_INFO_FAILED,
  GET_SYS_INFO_REQUEST,
  GET_SYS_INFO_SUCCESS,
} from 'constants/actionTypes/systemActionTypes'
import { API_GET_SYSTEM_INFO } from 'constants/apiPath'

const getSystemInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SYS_INFO_REQUEST })
    const sysResponse = await axios.get(API_GET_SYSTEM_INFO)
    const result = sysResponse.data
    if (result.data[0].DT_TODAY === 'system') {
      result.data[0].DT_TODAY = formatDateWithPadding(new Date(), 'yyyy-MM-dd')
    }
    if (result.data.length > 0) {
      sessionStorage.setItem('MP_DEADL', JSON.stringify(result.data[0].MP_DEADL))
    }
    switch (result.STS) {
      case 0:
        dispatch({ type: GET_SYS_INFO_SUCCESS, payload: result.data[0] })
        break
      case 9:
        dispatch({ type: GET_SYS_INFO_FAILED, payload: null })
        break
      default:
        break
    }
  } catch (e) {
    dispatch({ type: GET_SYS_INFO_FAILED, payload: e.message })
  }
}

export { getSystemInfo }

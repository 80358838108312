// import Allergen from 'views/Allergen/Allergen';
// import AllergenCreateEdit from 'views/Allergen/AllergenCreateEdit/AllergenCreateEdit';
// import Branch from 'views/Branch/Branch';
// import FormBranch from 'views/Branch/FormBranch/FormBranch';
// import ChangePassWord from 'views/changePassword/changePassword';
// import Compliance from 'views/Compliance/Compliance';
// import Customer from 'views/Customer/Customer';
// import CustomerCreateEdit from 'views/Customer/CustomerCreateEdit/CustomerCreateEdit';
// import CustUser from 'views/CustUser/CustUser';
// import CustUserCreateEdit from 'views/CustUser/CustUserCreateEdit/CustUserCreateEdit';
// import DeliveryData from 'views/DeliveryData/DeliveryData';
// import DeliveryDataDetail from 'views/DeliveryDataDetail/DeliveryDataDetail';
// import DistributionData from 'views/DistributionData/DistributionData';
// import FormDistributionData from 'views/DistributionData/FormDistributionData/FormDistributionData';
// import DownloadStatusDetail from 'views/DownloadStatusDetail/DownloadStatusDetail';
// import ExcelTemplate from 'views/ExcelTemplate/ExcelTemplate';
// import ExcelTemplateCreateEdit from 'views/ExcelTemplate/ExcelTemplateCreateEdit/ExcelTemplateCreateEdit';
// import FormHeadQuarter from 'views/HeadQuarters/FormHeadQuarter/FormHeadQuarter';
// import HeadQuarters from 'views/HeadQuarters/HeadQuarters';
// import Maintenance from 'views/Maintenance/Maintenance';
// import PortalCreateEdit from 'views/Maintenance/PortalCreateEdit/PortalCreateEdit';
import ChangePassword from 'views/ChangePassword/ChangePassword'
import Compliance from 'views/Compliance/Compliance'
import DataDownload from 'views/DataDownload/DataDownload'
import DeliveryData from 'views/DeliveryData/DeliveryData'
import DeliveryDataDetail from 'views/DeliveryDataDetail/DeliveryDataDetail'
import MyPage from 'views/MyPage/MyPage'
import Portal from 'views/Portal/Portal'

import FormCompliance from './views/Compliance/FormCompliance/FormCompliance'
// import ReceptionData from 'views/ReceptionData/ReceptionData';

// import DownloadStatus from './views/DownloadStatus/DownloadStatus';

const rootMap = new Map()
rootMap.set('portal', Portal)
rootMap.set('compliance-check', Compliance)
rootMap.set('data-download', DataDownload)
rootMap.set('delivery-data', DeliveryData)
rootMap.set('change-password', ChangePassword)

rootMap.set('form-compliance-check', FormCompliance)
rootMap.set('my-page', MyPage)
// rootMap.set('portal-master', Maintenance);
// rootMap.set('portal-master-form', PortalCreateEdit);
// rootMap.set('head-quarters', HeadQuarters);
// rootMap.set('head-quarters-form', FormHeadQuarter);
// rootMap.set('branch', Branch);
// rootMap.set('branch-form', FormBranch);
// rootMap.set('customer', Customer);
// rootMap.set('custuser', CustUser);
// rootMap.set('custuser-form', CustUserCreateEdit);
// rootMap.set('customer-form', CustomerCreateEdit);
// rootMap.set('distribution-data', DistributionData);
// rootMap.set('distribution-data-form', FormDistributionData);
// rootMap.set('allergen', Allergen);
// rootMap.set('allergen-form', AllergenCreateEdit);
// rootMap.set('excel-template', ExcelTemplate);
// rootMap.set('excel-template-form', ExcelTemplateCreateEdit);
// rootMap.set('reception-data', ReceptionData);
// rootMap.set('compliance', Compliance);
// rootMap.set('download', DownloadStatus);
// rootMap.set('download-detail', DownloadStatusDetail);
// rootMap.set('delivery-data', DeliveryData);
rootMap.set('delivery-data-detail', DeliveryDataDetail)

const extPage = [
  // { ext: 'change-password', name: 'パスワード変更', root: 'portal' },
  { ext: 'form-compliance-check', name: 'コンプライアンスチェック一覧', root: 'compliance-check' },
  // { ext: 'portal-master-form', name: 'ポータルマスタメンテナンス', root: 'portal-master' },
  // { ext: 'head-quarters-form', name: '本部担当者マスタメンテナンス', root: 'head-quarters' },
  // { ext: 'branch-form', name: '本部担当者マスタメンテナンス', root: 'branch' },
  // { ext: 'custuser-form', name: '取引先担当者マスタメンテナンス', root: 'custuser' },
  // { ext: 'customer-form', name: '取引先マスタメンテナンス', root: 'customer' },
  // { ext: 'distribution-data-form', name: '配信データマスタメンテナンス', root: 'distribution-data' },
  // { ext: 'allergen-form', name: 'アレルゲンマスタメンテナンス', root: 'allergen' },
  // { ext: 'excel-template-form', name: 'EXCELテンプレートメンテナンス', root: 'excel-template' },
  // { ext: 'download-detail', name: 'ダウンロード状況明細', root: 'download' },
  { ext: 'delivery-data-detail', name: '配信データ件数明細', root: 'delivery-data' },
]
const extFilterPage = extPage.map((e) => e.root)

const getDashBoardMenusOfUser = (menuList) => {
  const dashboardRoutes = []
  const listMenusOfUser = [...menuList]

  listMenusOfUser.forEach((menu) => {
    let obj = {}
    if (menu.MN_MROOT && menu.MN_MNAME) {
      obj = {
        path: `/${menu.MN_MROOT}`,
        name: `${menu.MN_SCRN1 || menu.MN_MNAME}`,
        component: rootMap.get(`${menu.MN_MROOT}`) || '',
        layout: '/customer',
        menuName: menu.MN_MNAME || '',
      }
    }
    if (menu.MN_MICON)
      obj = {
        path: `/${menu.MN_MROOT || ''}`,
        name: `${menu.MN_MNAME}`,
        component: rootMap.get(`${menu.MN_MROOT}`) || '',
        layout: '/customer',
        icon: menu.MN_MICON,
        menuName: menu.MN_MNAME || '',
      }
    obj = { ...obj, type: Number(menu.KB_TITLE) }
    let indexOfExt = extFilterPage.indexOf(menu.MN_MROOT)
    if (indexOfExt > -1 && extPage.length > 0) {
      let obj = {
        path: '/' + extPage[indexOfExt]?.ext,
        name: menu.MN_SCRN2,
        component: rootMap.get(extPage[indexOfExt].ext),
        layout: '/customer',
        menuName: menu.MN_MNAME || '',
        ext: true,
      }
      dashboardRoutes.push(obj)
    }
    if (menu.MN_MROOT === 'portal') {
      obj = {
        path: '/portal',
        name: 'ポータルページ',
        component: rootMap.get('portal'),
        layout: '/customer',
        menuName: menu.MN_MNAME || '',
        ext: true,
      }
    }
    dashboardRoutes.push(obj)

    // if (menu.MN_MROOT === 'compliance') {
    //   obj = {
    //     path: '/compliance',
    //     name: 'コンプライアンスチェック一覧',
    //     component: rootMap.get('compliance'),
    //     layout: '/admin',
    //     menuName: 'portal',
    //     ext: true,
    //   }
    //   dashboardRoutes.push(obj)
    // }
  })

  let obj = {
    path: '/change-password',
    name: 'パスワード変更',
    component: rootMap.get('change-password'),
    layout: '/customer',
    menuName: '',
    ext: true,
  }
  dashboardRoutes.push(obj)
  // My page
  dashboardRoutes.push({
    path: '/my-page',
    name: 'マイページ',
    component: rootMap.get('my-page'),
    layout: '/customer',
    menuName: 'マイページ',
    ext: true,
  })

  // Change pass
  dashboardRoutes.push({
    path: '/change-password',
    name: 'パスワード変更',
    component: rootMap.get('change-password'),
    layout: '/customer',
    menuName: 'パスワード変更',
    ext: true,
  })

  return dashboardRoutes
}

export { getDashBoardMenusOfUser }

import {
  CREATE_PORTAL_FAILED,
  CREATE_PORTAL_REQUEST,
  CREATE_PORTAL_SUCCESS,
  DELETE_PORTAL_FAILED,
  DELETE_PORTAL_REQUEST,
  DELETE_PORTAL_SUCCESS,
  GET_PORTAL_LIST_FAILED,
  GET_PORTAL_LIST_REQUEST,
  GET_PORTAL_LIST_SUCCESS,
  RESET_PORTAL,
  UPDATE_PORTAL_FAILED,
  UPDATE_PORTAL_REQUEST,
  UPDATE_PORTAL_SUCCESS,
} from 'constants/actionTypes/portalActionTypes'

const listPortals = (state = { listPortals: [] }, action) => {
  switch (action.type) {
    case GET_PORTAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_PORTAL_LIST_REQUEST,
      }
    case GET_PORTAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listPortals: action.payload.data,
        filterTypes: action.payload.message,
        type: GET_PORTAL_LIST_SUCCESS,
      }
    case GET_PORTAL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listPortals: [],
        errorMessage: action.payload.data,
        type: GET_PORTAL_LIST_FAILED,
      }
    default:
      return state
  }
}

const createPortal = (state = { portal: {} }, action) => {
  switch (action.type) {
    case CREATE_PORTAL_REQUEST:
      return {
        ...state,
        loading: true,
        portal: action.payload,
        type: CREATE_PORTAL_REQUEST,
      }
    case CREATE_PORTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        portal: { ...action.payload },
        status: 'success',
        type: CREATE_PORTAL_SUCCESS,
      }
    case CREATE_PORTAL_FAILED:
      return {
        ...state,
        loading: false,
        portal: action.payload,
        errorMessage: action.payload,
        status: 'failed',
        type: CREATE_PORTAL_FAILED,
      }
    case RESET_PORTAL:
      return { ...state, loading: false, status: null, portal: {}, errorMessage: '', type: RESET_PORTAL }
    default:
      return state
  }
}

const updatePortal = (state = { portal: {} }, action) => {
  switch (action.type) {
    case UPDATE_PORTAL_REQUEST:
      return {
        ...state,
        loading: true,
        portal: action.payload,
        type: UPDATE_PORTAL_REQUEST,
      }
    case UPDATE_PORTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        portal: { ...action.payload },
        status: 'success',
        type: UPDATE_PORTAL_SUCCESS,
      }
    case UPDATE_PORTAL_FAILED:
      return {
        ...state,
        loading: false,
        portal: action.payload,
        errorMessage: action.payload,
        status: 'failed',
        type: UPDATE_PORTAL_FAILED,
      }
    case RESET_PORTAL:
      return { ...state, loading: false, status: null, portal: {}, errorMessage: '', type: RESET_PORTAL }
    default:
      return state
  }
}

const deletePortal = (state = { portal: {} }, action) => {
  switch (action.type) {
    case DELETE_PORTAL_REQUEST:
      return {
        ...state,
        loading: true,
        type: DELETE_PORTAL_REQUEST,
      }
    case DELETE_PORTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        portal: action.payload,
        type: DELETE_PORTAL_SUCCESS,
      }
    case DELETE_PORTAL_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        type: DELETE_PORTAL_FAILED,
      }
    default:
      return state
  }
}

export { createPortal, deletePortal, listPortals, updatePortal }

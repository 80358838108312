import { combineReducers } from 'redux'

import { listAllergen } from './allergenReducer'
import { changePassReducer } from './changePassReducer'
import { codeInfo } from './codeReducers'
import { listCompliance, updateCompliance } from './complianceReducer'
import { getCustomerName } from './custUserReducers'
import { listDataDownload, updateDataDownload } from './dataDownloadReducers'
import { listDeliveryDataDetail } from './deliveryDataDetailReducer'
import { listDeliveryData } from './deliveryDataReducer'
import { forgotPasswordReducer } from './forgotPassReducers'
import { getMyPage, updateMyPage } from './myPageReducer'
import { listPortals } from './portalReducers'
import { systemInfo } from './systemReducers'
import { userLoginReducer } from './userReducers'
import { userInfoReducer } from './userReducers'
const appReducer = combineReducers({
  forgotPass: forgotPasswordReducer,
  userInfo: userLoginReducer,
  listPortals: listPortals,
  codeInfo,
  listCompliance,
  listAllergen,
  systemInfo,
  listDataDownload,
  updateDataDownload,
  listDeliveryData,
  listDeliveryDataDetail,
  userChangePass: changePassReducer,
  updateCompliance,
  updateMyPage,
  getMyPage,
  getCustomerName,
  userInfoDetail: userInfoReducer
})

const rootReducer = (state, action) => {
  // clear all state in case 'USER_LOGOUT'
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  // combine state
  return appReducer(state, action)
}

export default rootReducer

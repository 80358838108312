import { useEffect, useState } from 'react'

const getWindowDimensions = () => {
  const { innerWidth: width } = window
  const SIDE_BAR_WIDTH = 200
  const MARGIN_TABLE_RIGHT_LEFT = 35
  const tableDeliveryWidth = width - SIDE_BAR_WIDTH - MARGIN_TABLE_RIGHT_LEFT
  return {
    tableDeliveryWidth,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions

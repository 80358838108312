import './MyPage.css'

// import 'components/commons/FormEdits/FormEdit'
import Button from '@material-ui/core/Button'
import { Info, MailOutline } from '@material-ui/icons'
// import { getCustomerNameAction } from 'actions/custUserActions.js'
import { clearMyPage, getMyPageAction, updateMyPageAction } from 'actions/myPageActions'
import { IMAGES } from 'assets'
import { formatDateWithPadding, getDateInfoSystem, getSessionInfo, reRenderMessageSplitDot } from 'commons/utilities'
import { CustomCheckbox } from 'components/commons'
import {
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
} from 'components/commons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import Information from 'components/commons/Information/Information'
// import Information from 'components/commons/Information/Information'
import {
  GET_MY_PAGE_FAILED,
  GET_MY_PAGE_REQUEST,
  GET_MY_PAGE_SUCCESS,
  UPDATE_MY_PAGE_FAILED,
  UPDATE_MY_PAGE_REQUEST,
  UPDATE_MY_PAGE_SUCCESS,
} from 'constants/actionTypes/myPageActionTypes'
import { SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import changePasswordValidate from 'validation/changePassValidate'
import { changePasswordValidate, myPageValidate } from 'validation/myPageValidate.js'

// import ChangePassWord from 'views/changePassword/changePassword'
import styles from './MyPageStyle'

function MyPage() {
  const [error, setError] = useState({})
  const history = useHistory()
  const classes = styles()
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const userState = useSelector((state) => state.userInfo)
  const { userInfo } = userState
  const updateMyPageReducer = useSelector((state) => state.updateMyPage || {})
  const getMyPageReducer = useSelector((state) => state.getMyPage || {})
  const { hquser } = getMyPageReducer

  const dispatch = useDispatch()
  const [getDataloading, setGetDataLoading] = useState(true)
  const [updateloading, setUpdateLoading] = useState(false)

  useEffect(() => {
    dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
  }, [dispatch])

  const initialDataForm = {
    ID_USER: userInfo?.data?.ID_USER || '',
    NM_USER: '',
    NM_MAIL1: '',
    NM_MAIL2: '',
    NU_TEL: userInfo?.data?.NU_TEL || '',
    DT_DEADL: getDateInfoSystem().DT_DEFAULT,
    DT_REGIST: '',
    DT_UPDATE: '',
    KB_DATA: '',
    KB_SYSTEM: '',
    KB_TRN: '0',
    KB_DL: '0',
    KB_ADMIN: '1',
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [dataForm, setDataForm] = useState(initialDataForm)

  const [isInputPassword, setIsInputPassword] = useState(false)
  const { i18n, t } = useTranslation()

  // hide message error base on form input field
  const hideMessageError = (name = '') => {
    if (name === 'NM_MAIL1') {
      setError({ ...error, ...{ email1: '' } })
    }

    if (name === 'NM_MAIL2') {
      setError({ ...error, ...{ email2: '' } })
    }
  }

  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setIsBlocked(true)
    if (name === 'KB_DATA') {
      setDataForm((prev) => ({ ...prev, KB_DATA: (value + 0).toString() }))
      return
    }
    if (name === 'KB_TRN') {
      setDataForm((prev) => ({ ...prev, KB_TRN: (value + 0).toString() }))
      return
    }
    if (name === 'KB_DL') {
      setDataForm((prev) => ({ ...prev, KB_DL: (value + 0).toString() }))
      return
    }
    if (name === 'KB_SYSTEM') {
      setDataForm((prev) => ({ ...prev, KB_SYSTEM: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const checkRequire = React.useCallback(() => {
    if (!dataForm.NM_USER || !dataForm.NM_MAIL1 || !dataForm.NU_TEL) return false
    return isBlocked
  }, [dataForm, isBlocked])

  const isErrorUserInfo = (error = {}) => {
    const { email1, email2 } = error
    if (email1 || email2) {
      return true
    }
    return false
  }

  const isErrorChangePassword = (error = {}) => {
    const { errorNewPassword, errorPassword, errorConfirmPassword } = error
    if (errorNewPassword || errorPassword || errorConfirmPassword) {
      return true
    }
    return false
  }

  useEffect(() => {
    const { password, newPassword, confirmPassword } = dataForm
    if (password.length > 0 || newPassword.length > 0 || confirmPassword.length > 0) {
      setIsInputPassword(true)
    } else {
      setIsInputPassword(false)
    }
    if (isErrorUserInfo(error) || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
    if (dataForm.password || dataForm.newPassword || dataForm.confirmPassword) {
      if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword && !isErrorChangePassword(error)) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
      }
    }
  }, [dataForm, error, checkRequire])

  const handleSubmit = (e) => {
    e.preventDefault()
    const { email1, email2 } = myPageValidate(dataForm.NM_MAIL1, dataForm.NM_MAIL2, i18n)
    if (email1 || email2) {
      setError({ email1, email2 })
      return
    }
    if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword) {
      const { currentPasswordError, newPasswordError, confirmPasswordError } = changePasswordValidate(
        hquser?.ID_PASSW,
        dataForm.password,
        dataForm.newPassword,
        dataForm.confirmPassword,
        i18n
      )
      if (currentPasswordError || newPasswordError || confirmPasswordError) {
        const errorPassword = {
          errorPassword: currentPasswordError ? currentPasswordError : undefined,
          errorNewPassword: newPasswordError ? newPasswordError : undefined,
          errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
        }
        setError({
          ...error,
          ...errorPassword,
        })
        return
      }
    }

    setShowModal(true)
    setError()
  }

  const submitData = () => {
    const submitData = { ...dataForm, CD_CUST: userInfo?.data?.CD_CUST }
    dispatch(updateMyPageAction(submitData))
    setIsBlocked(false)
    setShowModal(false)
    setDataForm((prev) => ({
      ...prev,
      password: '',
      newPassword: '',
      confirmPassword: '',
    }))
  }

  useEffect(() => {
    switch (getMyPageReducer.type) {
      case GET_MY_PAGE_REQUEST:
        break
      case GET_MY_PAGE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(getMyPageReducer.message)
        }
        break
      case GET_MY_PAGE_SUCCESS:
        if (Object.keys(hquser).length > 0) {
          setDataForm((prev) => ({
            ...prev,
            NM_USER: hquser.NM_USER || '',
            NM_MAIL1: hquser.NM_MAIL1 || '',
            NM_MAIL2: hquser.NM_MAIL2 || '',
            KB_DATA: hquser.KB_DATA || '',
            KB_SYSTEM: hquser.KB_SYSTEM || '',
            KB_ADMIN: hquser.KB_ADMIN || '',
            NU_TEL: hquser.NU_TEL || '',
            KB_DL: hquser.KB_DL || '',
            KB_TRN: hquser.KB_TRN || '',
            DT_UPDATE: formatDateWithPadding(new Date(hquser.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss') || '',
            password: '',
            newPassword: '',
            confirmPassword: '',
          }))
        }

        break
      default:
        return
    }
    setGetDataLoading(getMyPageReducer.loading)
    setIsBlocked(false)
  }, [getMyPageReducer])

  useEffect(() => {
    setUpdateLoading(updateMyPageReducer.loading)
    setIsBlocked(false)

    switch (updateMyPageReducer.type) {
      case UPDATE_MY_PAGE_REQUEST:
        break
      case UPDATE_MY_PAGE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateMyPageReducer.message)
        }
        break
      case UPDATE_MY_PAGE_SUCCESS:
        history.push({
          pathname: '/customer/my-page',
        })
        dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
        dispatch(clearMyPage())
        break
      default:
        break
    }
  }, [updateMyPageReducer])

  // const resetErrorMessage = () => {
  //   setError({})
  // }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/customer/portal',
    })
  }

  const handleClearOnClick = () => {
    // setDataForm(initialDataForm)
    // resetErrorMessage()
    handleCancelButton()
  }

  if (getDataloading) {
    return <ProgressDialog label={t('myPage.loading')} />
  }

  const messageComfirm = isInputPassword
    ? reRenderMessageSplitDot(t('myPage.msgConfirmWithPassword.line1'), t('myPage.msgConfirmWithPassword.line2'))
    : reRenderMessageSplitDot(t('myPage.msgConfirmWithoutPassword.line1'), t('myPage.msgConfirmWithoutPassword.line2'))

  return (
    <div className="myPage_container ">
      <ModalConfirmNavigation
        title={reRenderMessageSplitDot(t('myPage.msgConfirmNavigate.line1'), t('myPage.msgConfirmNavigate.line2'))}
        isBlocked={isBlocked}
      />
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      <ModalConfirm
        title={messageComfirm}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />

      {updateloading ? (
        <ProgressDialog label={t('myPage.loading')} />
      ) : (
        <div className="myPage_wrapper">
          <div className="myPage_content">
            {/* Change HQ user  */}
            <div className="change_userInfo">
              {/* Header HQ user  */}
              <div className="userInfo_header">
                <div className="userInfo_header_text">
                  <div className="userInfo_header_text_wrapper">{t('changePassword.lblChangeHqHeaderText')}</div>
                </div>
              </div>
              {/* Form HQ user  */}
              <div className="userInfo_content formEdit-container">
                <form className="userInfo_form">
                  <div className="userInfo_form_container portal-create form-scroll">
                    <div className="portal-create-content form-min-width">
                      <div className="nm-user-container">
                        {/*NM_USER  */}
                        <FormInputs
                          id="standard-basic"
                          label={t('myPage.lblUserName')}
                          value={dataForm.NM_USER || ''}
                          inputProps={{ tabIndex: 2 }}
                          required
                          icon={
                            <Image
                              src={IMAGES.ICON.CARD_USER}
                              alt="Card User Icon"
                              className="iconForm icon--deactive"
                            />
                          }
                          type="text"
                          name="NM_USER"
                          setValue={(e) => handleUpdate('NM_USER', e)}
                          className="width450"
                        />
                      </div>

                      {/* CUSTOMER */}
                      <div className="customer-container">
                        <div className="wrap-icon">
                          <Image src={IMAGES.ICON.BRANCH.BUILDING} className="iconForm icon--deactive" />
                        </div>
                        <div className="customer-content">
                          <div className="customer-id">
                            {userInfo?.data?.hasOwnProperty('CD_CUST')
                              ? userInfo?.data?.CD_CUST + ' : '
                              : t('myPage.lblCustomerID') + ' : '}
                          </div>
                          <div className="customer-name">
                            {userInfo?.data?.hasOwnProperty('NM_CUST')
                              ? userInfo?.data?.NM_CUST
                              : t('myPage.lblCustomerName')}
                          </div>
                        </div>
                      </div>

                      <FormInputs
                        id="standard-basic"
                        label={t('forgotPassword.lblPhone')}
                        value={dataForm.NU_TEL || ''}
                        required
                        icon={<Image src={IMAGES.ICON.PHONE} alt="phone Icon" className="iconForm icon--deactive" />}
                        type="text"
                        name="NU_TEL"
                        setValue={(e) => handleUpdate('NU_TEL', e)}
                        className="width450"
                        inputProps={{ maxLength: 30, tabIndex: 3 }}
                        errorPosition={'105px'}
                        screen={SCREEN_NAME.MYPAGE}
                        onlyNumber
                      />

                      {/*NM_EMAIL1*/}
                      <FormInputs
                        id="standard-basic"
                        label={t('myPage.lblEmail1')}
                        value={dataForm.NM_MAIL1 || ''}
                        required
                        icon={<MailOutline className="icon--deactive" />}
                        type="email"
                        name="NM_MAIL1"
                        setValue={(e) => handleUpdate('NM_MAIL1', e)}
                        error={error && !!error.email1}
                        helperText={error && error.email1 ? error.email1 : null}
                        className="width450"
                        inputProps={{ tabIndex: 4 }}
                        errorPosition={'105px'}
                      />
                      {/*NM_MAIL2  */}
                      <FormInputs
                        id="standard-basic"
                        label={t('myPage.lblEmail2')}
                        value={dataForm.NM_MAIL2 || ''}
                        icon={<MailOutline className="icon--deactive" />}
                        type="email"
                        name="NM_MAIL2"
                        setValue={(e) => handleUpdate('NM_MAIL2', e)}
                        error={error && !!error.email2}
                        helperText={error && error.email2 && dataForm.NM_MAIL2 ? error.email2 : null}
                        className="width450"
                        inputProps={{ tabIndex: 5 }}
                        errorPosition={'105px'}
                      />
                      <div className={classes.customCheckbox}>
                        <CustomCheckbox
                          inputProps={{ tabIndex: 6 }}
                          label={t('myPage.lblDeliveryNoti')}
                          checked={Boolean(Number(dataForm.KB_TRN))}
                          onChange={(e) => handleUpdate('KB_TRN', e)}
                          className="checkbox"
                        />
                        <Information
                          icon={<Info className={classes.iconInfo} />}
                          message={t('myPage.infoDeliveryNoti')}
                          screen="mypage"
                        />
                      </div>
                      <div className={classes.customCheckbox}>
                        <CustomCheckbox
                          inputProps={{ tabIndex: 7 }}
                          label={t('myPage.lblUnReceivedNoti')}
                          checked={Boolean(Number(dataForm.KB_DL))}
                          onChange={(e) => handleUpdate('KB_DL', e)}
                          className="checkbox"
                        />
                        <Information
                          icon={<Info className={classes.iconInfo} />}
                          message={t('myPage.infoUnReceivedNoti')}
                          screen="mypage"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* Change PassWord  */}
            <div className="change_userPass">
              {/* Header Change PassWord  */}
              <div className="change_userPass_header">
                <div className="change_userPass_header_text">
                  <div className="change_userPass_header_text_wrapper">
                    {t('changePassword.lblChangePassHeaderText')}
                  </div>
                </div>
              </div>
              {/* Form Change PassWord  */}
              <div className="userInfo_content">
                <form className="userInfo_form">
                  <div className="userInfo_form_container portal-create form-scroll">
                    <div className="portal-create-content form-min-width">
                      <div className="changepass-container">
                        <PasswordFormInput
                          password={dataForm.password}
                          setPassword={(e) => handleUpdate('password', e)}
                          errorMessage={error?.errorPassword}
                          setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
                          label={t('changePassword.lblOldPassword')}
                          tabIndex={8}
                          screen={SCREEN_NAME.MYPAGE}
                          required={isInputPassword}
                        ></PasswordFormInput>
                        <PasswordFormInput
                          password={dataForm.newPassword}
                          setPassword={(e) => handleUpdate('newPassword', e)}
                          errorMessage={error?.errorNewPassword}
                          setErrorMessage={(value) => setError({ ...error, ...{ errorNewPassword: value } })}
                          label={t('changePassword.lblNewPassword')}
                          tabIndex={9}
                          screen={SCREEN_NAME.MYPAGE}
                          required={isInputPassword}
                        ></PasswordFormInput>
                        <PasswordFormInput
                          password={dataForm.confirmPassword}
                          setPassword={(e) => handleUpdate('confirmPassword', e)}
                          errorMessage={error?.errorConfirmPassword}
                          screen={SCREEN_NAME.MYPAGE}
                          setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
                          label={t('myPage.lblPassWordReEnter')}
                          tabIndex={10}
                          required={isInputPassword}
                        ></PasswordFormInput>

                        {/* Form User Update, Delete Time  */}
                        <div className="change_userPass_infor formEdit-container">
                          <div className="formEdit-input">
                            <div className="input-spacing"></div>
                            <FormInputs
                              id="standard-basic"
                              canSpacing={true}
                              label={t('changePassword.lblExpireDate')}
                              value={userInfo?.data?.DT_DEADL || ''}
                              type="text"
                              name="DT_DEADL"
                              inputProps={{ tabIndex: 11 }}
                              disabled
                              className="width120"
                            />
                          </div>

                          <div className="formEdit-input">
                            <div className="input-spacing"></div>
                            <FormInputs
                              id="standard-basic"
                              canSpacing={true}
                              label={t('changePassword.lblUpdatePerson')}
                              type="text"
                              value={dataForm.ID_USER || ''}
                              name="DT_DELETE"
                              disabled
                              className="width210"
                              inputProps={{ tabIndex: 12 }}
                            />
                          </div>

                          <div className="formEdit-input">
                            <div className="input-spacing"></div>
                            <div className="formEdit-input wrap-mb">
                              <FormInputs
                                id="standard-basic"
                                canSpacing={true}
                                label={t('changePassword.lblRegisteredDate')}
                                value={
                                  formatDateWithPadding(new Date(userInfo?.data?.DT_REGIST), 'yyyy-MM-dd HH:mm:ss') ||
                                  ''
                                }
                                inputProps={{ tabIndex: 13 }}
                                type="text"
                                name="DT_REGIST"
                                disabled
                                className="width160"
                              />
                              <FormInputs
                                id="standard-basic"
                                canSpacing={true}
                                label={t('changePassword.lblUpdatedDate')}
                                value={dataForm.DT_UPDATE || ''}
                                type="text"
                                inputProps={{ tabIndex: 14 }}
                                name="DT_UPDATE"
                                disabled
                                className="width160"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="btn-container">
                          <Button
                            className="button submit-button"
                            type="submit"
                            variant="contained"
                            tabIndex={15}
                            disabled={!canSubmit}
                            onClick={handleSubmit}
                          >
                            <span className="btn-submit">{t('changePassword.lblChangePasswordConfirm')}</span>
                          </Button>
                          <Button
                            className="button clear-button"
                            variant="outlined"
                            tabIndex={16}
                            onClick={handleClearOnClick}
                          >
                            <span className="btn-cancel"> {t('myPage.btnCancel')}</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MyPage

import './Admin.css'

import { getSystemInfo } from 'actions/systemActions'
import Footer from 'components/commons/Footer/Footer'
import Navbar from 'components/commons/Navbar/Navbar'
import Sidebar from 'components/commons/Sidebar/Sidebar'
import { FORM_KEYWORD } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getDashBoardMenusOfUser } from 'routes'
function Admin(props) {
  const [toggle, setToggle] = useState(false)
  const [routers, setRouters] = useState([])
  const [redirect, setRedirect] = useState(false)
  const [session, setSession] = useState(0)
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  // useEffect(() => {
  //   generateRandomKey()
  // })

  const generateRandomKey = () => {
    let isRenderComponent = false
    for (let item in FORM_KEYWORD) {
      if (window.location.pathname.includes(FORM_KEYWORD[item])) {
        isRenderComponent = true
        break
      }
    }
    if (isRenderComponent === false) setSession((Math.random() + 1).toString(36).substring(7))
  }

  const handleSidebarToggle = () => {
    setToggle(!toggle)
  }
  const getRoutes = (routes) => {
    return [...routes].map((prop, key) => {
      if (prop.path) {
        if (prop.component)
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => {
                return <prop.component {...props} />
              }}
              key={'route' + session + key}
            />
          )
        // return <Route exact path={prop.layout + prop.path} render={''} key={'route' + generateRandomKey() + key} />
      } else {
        return null
      }
    })
  }

  const getBrandText = (path) => {
    let index = routers.map((r) => r.path).indexOf(path.replace('/customer', ''))
    if (index < 0) return ''
    return routers[index].name
  }
  useEffect(() => {
    dispatch(getSystemInfo())
  }, [dispatch])

  useEffect(() => {
    if (!userInfo) return setRedirect(true)
    const dashboardRoutes = getDashBoardMenusOfUser(userInfo.menuList)
    setRouters(dashboardRoutes)
  }, [userInfo])

  if (redirect) return <Redirect to="/login" />
  return (
    <div className="wrapper">
      <div className={`side-bar ${toggle && 'mob_show'}`}>
        <Sidebar
          mobileOpen={toggle}
          handleSidebarToggle={handleSidebarToggle}
          routers={routers}
          rendererRoute={generateRandomKey}
        />
      </div>
      <div className={`main-panel ${toggle && 'mob_show'}`}>
        <Navbar
          mobileOpen={toggle}
          handleSidebarToggle={handleSidebarToggle}
          brandText={getBrandText(props.location.pathname)}
        />
        <div
          className={
            props?.location?.pathname === '/customer/compliance-check' ? 'compliance-scroll' : 'hiddenOnScroll'
          }
        >
          <Switch>{getRoutes(routers)}</Switch>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Admin

import {
  CLEAR_DATA_DOWNLOAD,
  GET_DATA_DOWNLOAD_LIST_FAILED,
  GET_DATA_DOWNLOAD_LIST_REQUEST,
  GET_DATA_DOWNLOAD_LIST_SUCCESS,
  UPDATE_DATA_DOWNLOAD_FAILED,
  UPDATE_DATA_DOWNLOAD_REQUEST,
  UPDATE_DATA_DOWNLOAD_SUCCESS,
} from 'constants/actionTypes/dataDownloadActionTypes'
const listDataDownload = (state = { listDataDownload: [] }, action) => {
  switch (action.type) {
    case GET_DATA_DOWNLOAD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_DATA_DOWNLOAD_LIST_REQUEST,
      }
    case GET_DATA_DOWNLOAD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listDataDownload: action.payload,
        type: GET_DATA_DOWNLOAD_LIST_SUCCESS,
      }
    case GET_DATA_DOWNLOAD_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listDataDownload: [],
        message: action.payload,
        type: GET_DATA_DOWNLOAD_LIST_FAILED,
      }
    default:
      return state
  }
}
const updateDataDownload = (state = { dataDownload: {} }, action) => {
  switch (action.type) {
    case UPDATE_DATA_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        dataDownload: action.payload,
        type: UPDATE_DATA_DOWNLOAD_REQUEST,
      }
    case UPDATE_DATA_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        dataDownload: { ...action.payload },
        type: UPDATE_DATA_DOWNLOAD_SUCCESS,
        message: 'success',
      }
    case UPDATE_DATA_DOWNLOAD_FAILED:
      return {
        ...state,
        loading: false,
        dataDownload: {},
        message: action.payload,
        type: UPDATE_DATA_DOWNLOAD_FAILED,
      }
    case CLEAR_DATA_DOWNLOAD:
      return { type: CLEAR_DATA_DOWNLOAD, loading: false, status: null, dataDownload: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, dataDownload: {}, message: '' }
  }
}
export { listDataDownload, updateDataDownload }

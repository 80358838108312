import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
  fullWidthTable: {
    minWidth: 1050,
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  testedTable: {
    // minWidth: 1050,
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  smallWidthTable: {
    width: '900px',
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: '#f2f2f2',
  },
  searchWrapper: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  searchContainer: {
    with: '100%',
    height: '100%',
  },
  filterWrapper: {
    height: '40px',
    padding: '0 0 0 100px',
    display: 'flex',
    flexDirection: 'row',
  },
  sortedWrapper: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  selectSorted: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  message: {
    width: '250px',
    wordWrap: 'break-word',
    padding: '5px 0px',
  },
  textMessage: {
    fontSize: '11px',
    display: 'flex',
    alignItems: 'center',
  },
  iconUpdate: {
    padding: '10px 0px 10px 15px',
  },
  titleHead: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: '0',
  },
  paddingHeadDelivery: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    borderBottom: '0',
    padding: '8px 15px 8px 15px',
  },
  titleHeadDistribution: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: '0',
    textAlign: 'center',
  },
  titleHeadExceptionDistribution: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: '0',
    textAlign: 'end',
  },
  classification: {
    paddingLeft: '16px',
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  data: {
    fontSize: '11px',
    // padding: '0px 10px',
    padding: '0px 15px 0px 15px',
  },
  dataRight: {
    fontSize: '11px',
    padding: 0,
  },
  dataCustUser: {
    fontSize: '11px',
    padding: '0px 15px',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  iconAction: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  iconRedirect: {
    width: '23px',
    height: '23px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  urlData: {
    color: '#5e7bae',
    cursor: 'pointer',
    fontSize: '11px',
    padding: '16px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  url: {
    paddingLeft: '0px',
  },
  title: {
    paddingLeft: '49px',
  },
  iconBuild: {
    color: 'rgb(85, 130, 50)',
    fontSize: '26px',
  },
  iconPreview: {
    color: 'rgb(85, 130, 50)',
    transform: 'scale(2.8)',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  toolbarWrapper: {
    height: '40px',
  },
  iconFilter: {
    width: '16px',
    height: '18px',
    marginRight: '10px',
  },
  iconReverse: {
    width: '20px',
    height: '20px',
  },
  iconDate: {
    width: '14px',
    height: '14px',
    position: 'absolute',
    top: '12px',
    right: '50px',
    cursor: 'pointer',
  },
  iconReturn: {
    width: '25px',
    height: '25px',
    cursor: 'pointer',
  },
  email: {
    display: 'flex',
    alignItems: 'center',
  },
  emailContainer: {
    display: 'flex',
  },
  iconEmail: {
    margin: '0px 15px 0px 5px',
    width: '15px',
    height: '15px',
  },
  dateTitle: {
    position: 'relative',
    padding: '8px 0px',
    cursor: 'pointer',
  },
  personTitle: {
    padding: '8px 10px 8px 0px',
  },
  personTitleDelivery: {
    padding: '0px 15px',
  },
  tableRow: {
    backgroundColor: '#f5faeb',
  },
  centerAlign: {
    // height: '40.04px',
    // lineHeight: '20.02px', // cố định chiều cao = 50px (yêu cầu ngày 15/11)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // require 15/11
    height: 0,
  },
  centerAlignHq: {
    height: '30px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(59, 56, 56)',
  },
  centerAlignDistribution: {
    height: '30px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(59, 56, 56)',
    justifyContent: 'center',
  },
  overFlowTextId: {
    height: '30px',
    lineHeight: '20px',
    alignItems: 'center',
    color: 'rgb(59, 56, 56)',
    whiteSpace: 'nowrap',
    width: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overFlowTextName: {
    height: '30px',
    lineHeight: '20px',
    alignItems: 'center',
    color: 'rgb(59, 56, 56)',
    whiteSpace: 'nowrap',
    width: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dataRightHq: {
    fontSize: '11px',
    padding: '5px 10px 5px 0px',
  },
  dataDeliveryDetail: {
    fontSize: '11px',
    padding: '5px 15px',
  },
  dataCommon: {
    fontSize: '11px',
    padding: '0px 15px',
  },
  dataCommonDistribution: {
    fontSize: '11px',
    padding: '0px 15px 0px 0px',
  },
  titleData: {
    width: 'calc(100% - 35px)',
    display: 'flex',
    alignItems: 'center',
  },
  personSender: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
  },
  modalStyle: {
    height: '20px',
    width: '20px',
    margin: '0px 5px 0px 0px !important',
    color: 'black !important',
  },
  titleSpacing: {
    paddingLeft: '13px',
  },
  overFlowText: {
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
  iconBlur: {
    color: 'rgb(59, 56, 56)',
    paddingLeft: 15,
    fontSize: 32,
  },
  iconSizeDistribution: {
    paddingLeft: 0,
    fontSize: '17px',
  },
  titleSpacingCust: {
    paddingLeft: '0px',
  },
  headerLine: {
    marginBottom: '7px',
  },
  smallWidthHeaderLine: {
    width: '900px',
    border: '2px solid rgb(85, 130, 50)',
  },
  fullWidthHeaderLine: {
    minWidth: '1050px',
    border: '2px solid rgb(85, 130, 50)',
  },

  recordCounterFullWidth: {
    color: 'rgb(85, 130, 50)',
    fontSize: '12px',
    // position: 'absolute',
    right: '20px',
    float: 'right',
  },
  recordCounterSmallWidth: {
    color: 'rgb(85, 130, 50)',
    fontSize: '12px',
    // position: 'relative',
    left: '690px',
    float: 'right',
  },
  middleItemFullWidth: {
    color: 'rgb(85, 130, 50)',
    fontSize: '12px',
    marginLeft: '350px',
  },
  middleItemSmallWidth: {
    color: 'rgb(85, 130, 50)',
    fontSize: '12px',
    marginLeft: '100px',
  },
  startLineItem: {
    color: 'rgb(85, 130, 50)',
    fontSize: '15px',
    position: 'relative',
    left: '0px',
  },
  customer: {
    color: 'rgb(59, 56, 56)',
    fontSize: '15px',
  },
  link: {
    color: 'red',
  },
  // Compliance Screen
  titleHeadCompliance: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    paddingTop: '5px',
    paddingBottom: '0px',
    borderBottom: '0',
    minWidth: '100px',
    whiteSpace: 'nowrap',
  },

  productTradeMarkContainer: {
    // display: 'flex',
  },
  tradeMark: {
    marginRight: '20px',
  },
  approvalDate: {
    textAlign: 'end',
    paddingRight: '40px',
  },

  // complianceRow: {
  //   display: 'flex',
  //   height: '60px',
  //   marginTop: '5px',
  //   width: '100%',
  // },
  complianceCell: {
    fontSize: '11px',
  },
  productCell: {
    width: '400px',
  },
  productNameCell: {
    width: '70%',
  },
  classificationCell: {
    width: '10%',
  },

  preFectureCell: {
    width: '10%',
  },
  complianceRowBody: {
    width: '100%',
  },
  complianceRow: {
    height: '50%',
  },
  complianceThirdRow: {
    height: '33%',
  },
  textRed: {
    color: 'red',
  },
  colorBlue: {
    backgroundColor: '#f5faeb',
  },
  complianceCellBorder: {
    borderBottom: '1px solid grey !important',
  },
  cellHeaderContainer: {
    maxWidth: '120px',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  deliveryDatePadding: {
    padding: '8px 15px',
  },
  '@media (max-width: 1570px)': {
    attachFile: {
      width: '10% !important',
      textAlign: 'center',
    },
  },
})

export default styles

import { Radio } from '@material-ui/core'
import React from 'react'
function RadioCommon({ checked, value, before = false, handleChangeCodeAF, tabIndex = 0 }) {
  const randomKeyRadio = () => {
    return (Math.random() * 10 + 1).toFixed(0)
  }
  return (
    <Radio
      id={randomKeyRadio()}
      checked={checked}
      disabled={before}
      value={value}
      name="radio-button-allergen-bf"
      inputProps={{ 'aria-label': 'A', tabIndex: tabIndex }}
      size="small"
      onChange={before ? () => {} : (e) => handleChangeCodeAF(e)}
    />
  )
}

export default RadioCommon

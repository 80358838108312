import axios from 'commons/baseUrl'
import {
  CLEAR_ALLERGEN,
  CREATE_ALLERGEN_FAILED,
  CREATE_ALLERGEN_REQUEST,
  CREATE_ALLERGEN_SUCCESS,
  DELETE_ALLERGEN_FAILED,
  DELETE_ALLERGEN_REQUEST,
  DELETE_ALLERGEN_SUCCESS,
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
  UPDATE_ALLERGEN_FAILED,
  UPDATE_ALLERGEN_REQUEST,
  UPDATE_ALLERGEN_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'
import { API_CREATE_ALLERGEN, API_DELETE_ALLERGEN, API_GET_ALLERGEN, API_UPDATE_ALLERGEN } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

// const res = {
//   data: {
//     data: [
//       {
//         CD_ALRG: 'carrot',
//         NM_ALRG: 'carrot',
//         DT_START: '2022-01-01',
//         DT_END: '2022-12-31',
//         NM_RUDUSER: 'datacom',
//         DT_REGIST: '2022-01-01',
//         DT_UPDATE: '2021-12-01',
//         DT_DELETE: '',
//         NM_USER: 'datacom',
//       },
//       {
//         CD_ALRG: 'fish',
//         NM_ALRG: 'fish',
//         DT_START: '2022-01-01',
//         DT_END: '2022-12-31',
//         NM_RUDUSER: 'datacom',
//         DT_REGIST: '2022-01-01',
//         DT_UPDATE: '2021-12-01',
//         DT_DELETE: '',
//         NM_USER: 'datacom',
//       },
//       {
//         CD_ALRG: 'fur',
//         NM_ALRG: 'fur',
//         DT_START: '2022-01-01',
//         DT_END: '2022-12-31',
//         NM_RUDUSER: 'datacom',
//         DT_REGIST: '2022-01-01',
//         DT_UPDATE: '2021-12-01',
//         DT_DELETE: '',
//         NM_USER: 'datacom',
//       },
//     ],
//     STS: 0,
//     error: false,
//     message: 'success',
//   },
// }
const getAllergenAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALLERGEN_LIST_REQUEST })
    const res = await axios.get(API_GET_ALLERGEN, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_ALLERGEN_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_ALLERGEN_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_ALLERGEN_LIST_FAILED, payload: err.message })
  }
}

const createAllergenAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ALLERGEN_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_ALLERGEN, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_ALLERGEN_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_ALLERGEN_FAILED,
        payload: i18n.t('validate.allergen.cdAllergenInvalid', {
          fieldName: i18n.t('allergen.lblCdAllergen'),
          cdAllergenValue: data.CD_ALRG,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_ALLERGEN_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_ALLERGEN_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_ALLERGEN_FAILED, payload: err.message })
  }
}

const updateAllergenAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ALLERGEN_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_ALLERGEN, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_ALLERGEN_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_ALLERGEN_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_ALLERGEN_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_ALLERGEN_FAILED, payload: err.message })
  }
}

const deleteAllergenAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ALLERGEN_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_ALLERGEN, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_ALLERGEN_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_ALLERGEN_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_ALLERGEN_SUCCESS, payload: res.data.data })
      dispatch(getAllergenAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_ALLERGEN_SUCCESS, payload: err.message })
  }
}

const clearAllergen = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_ALLERGEN, payload: '' })
}

export { clearAllergen, createAllergenAction, deleteAllergenAction, getAllergenAction, updateAllergenAction }

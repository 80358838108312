import './SidebarHeader.css'

import React from 'react'

function SidebarHeader({ icon, text, pathTo }) {
  return (
    <div className="sidebar-item-container">
      {/* <a href={pathTo || '#'}> */}
      <div className="sidebar-item-content">
        <div className="menu-icon">{icon}</div>
        <div className="menu-text">{text}</div>
      </div>
      {/* </a> */}
    </div>
  )
}

export default SidebarHeader

export const GET_ALLERGEN_LIST_REQUEST = 'GET_ALLERGEN_LIST_REQUEST'
export const GET_ALLERGEN_LIST_SUCCESS = 'GET_ALLERGEN_LIST_SUCCESS'
export const GET_ALLERGEN_LIST_FAILED = 'GET_ALLERGEN_LIST_FAILED'
export const CREATE_ALLERGEN_REQUEST = 'CREATE_ALLERGEN_REQUEST'
export const CREATE_ALLERGEN_FAILED = 'CREATE_ALLERGEN_FAILED'
export const CREATE_ALLERGEN_SUCCESS = 'CREATE_ALLERGEN_SUCCESS'
export const UPDATE_ALLERGEN_REQUEST = 'UPDATE_ALLERGEN_REQUEST'
export const UPDATE_ALLERGEN_FAILED = 'UPDATE_ALLERGEN_FAILED'
export const UPDATE_ALLERGEN_SUCCESS = 'UPDATE_ALLERGEN_SUCCESS'
export const DELETE_ALLERGEN_REQUEST = 'DELETE_ALLERGEN_REQUEST'
export const DELETE_ALLERGEN_FAILED = 'DELETE_ALLERGEN_FAILED'
export const DELETE_ALLERGEN_SUCCESS = 'DELETE_ALLERGEN_SUCCESS'
export const CLEAR_ALLERGEN = 'CLEAR_ALLERGEN'

import './ForgotPassword.css'

import { Button, Grid } from '@material-ui/core'
import { EmailOutlined, Person } from '@material-ui/icons'
import { clearData, forgotPassword } from 'actions/forgotPasswordActions'
import { IMAGES } from 'assets'
import { eventKeydown, reRenderMessageSplitDot } from 'commons/utilities'
import {
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirm as UpdateResult,
  ProgressDialog,
} from 'components/commons'
import {
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import forgotPasswordValidate from 'validation/forgotPasswordValidate'

function ForgotPassword() {
  const { i18n, t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const [showProgress, setShowProgress] = useState(false)
  const [showMessageErrorDialog, setShowMessageErrorDialog] = useState(false)
  const [error, setError] = useState({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [dataForm, setDataForm] = useState({
    ID_USER: '',
    EMAIL: '',
    CD_CUST: '',
    NU_TEL: '',
  })
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const forgotPassReducer = useSelector((state) => {
    return state.forgotPass
  })

  const [showModalUpdateResult, setShowModalUpdateResult] = useState(false)

  const checkRequire = React.useCallback(() => {
    if (!dataForm.ID_USER || !dataForm.EMAIL || !dataForm.CD_CUST || !dataForm.NU_TEL) {
      return false
    } else return true
  }, [dataForm])

  useEffect(() => {
    if (!checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, checkRequire])

  useEffect(() => {
    setShowProgress(forgotPassReducer?.loading)

    switch (forgotPassReducer.type) {
      case FORGOT_PASS_REQUEST:
        setShowProgress(true)
        break
      case FORGOT_PASS_SUCCESS:
        setShowModalUpdateResult(true)
        dispatch(clearData())
        break
      case FORGOT_PASS_FAILED:
        setShowMessageErrorDialog(true)
        setErrorMessage(
          reRenderMessageSplitDot(t('forgotPassword.msgErrorInvalid.line1'), t('forgotPassword.msgErrorInvalid.line2'))
        )
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassReducer, dispatch])

  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const hideMessageError = (name = '') => {
    if (name === 'EMAIL') {
      setError({ ...error, email: '' })
    }
    if (name === 'CD_CUST') {
      setError({ ...error, cdCust: '' })
    }
    if (name === 'ID_USER') {
      setError({ ...error, idUser: '' })
    }
  }

  // reset state before naviate to login page
  const handleCancel = () => {
    setErrorMessage('')
    setShowProgress(false)
    setShowMessageErrorDialog(false)
    history.push('/login')
  }

  // handle submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    let { idUser, email } = forgotPasswordValidate(dataForm.ID_USER, dataForm.EMAIL, i18n)

    if (idUser || email) {
      setError({ idUser, email })
      setCanSubmit(false)
      return
    } else {
      setShowModal(true)
    }
  }

  const submitData = () => {
    dispatch(forgotPassword(dataForm, i18n))
    setShowModal(false)
  }

  const modalConfirm = reRenderMessageSplitDot(
    t('myPage.msgConfirmWithPassword.line1'),
    t('myPage.msgConfirmWithPassword.line2')
  )

  const handleCloseUpdateModal = () => {
    setShowModalUpdateResult(false)
    history.push({
      pathname: '/login',
    })
  }
  return (
    <div className="wrapper__fpwd">
      {showProgress && <ProgressDialog label={t('forgotPassword.lblProcess')} />}
      {showMessageErrorDialog && (
        <MessageErrorDialog message={errorMessage} showMessageErrorDialog={setShowMessageErrorDialog} />
      )}
      <UpdateResult
        title={reRenderMessageSplitDot(
          t('forgotPassword.lblUpdateSuccess.line1'),
          t('forgotPassword.lblUpdateSuccess.line2'),
          t('forgotPassword.lblUpdateSuccess.line3')
        )}
        showModal={showModalUpdateResult}
        setShowModal={setShowModalUpdateResult}
        screen={'compliance'}
        handleFunction={handleCloseUpdateModal}
        buttonAlign={'alignEnd'}
      />
      <ModalConfirm
        title={modalConfirm}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      <Grid item md={12} xs={12} className="wrapper__fpwd-container">
        <div className="wrapper__fpwd-title">{t('forgotPassword.lblTitle')}</div>
        <div className="wrapper__fpwd-form">
          <form
            onKeyDown={(e) => {
              eventKeydown(e)
            }}
            autoCapitalize="off"
            autoCorrect="off"
          >
            <div className="wrapper__fpwd-helptext">
              <span className="section-note">※</span>
              <span>
                <div className="forgot-password-helper-text">{t('forgotPassword.lblHelpTextForm1')}</div>
                <div className="forgot-password-helper-text">{t('forgotPassword.lblHelpTextForm2')}</div>
              </span>
            </div>
            <FormInputs
              label={t('forgotPassword.lblId')}
              icon={<Person fontSize="medium" />}
              type="text"
              name="ID_USER"
              error={error && !!error.idUser}
              helperText={error && error.idUser ? error.idUser : null}
              errorTopPosition={'-7%'}
              errorPosition={'70px'}
              value={dataForm.ID_USER}
              setValue={(e) => handleUpdate('ID_USER', e)}
              inputProps={{ tabIndex: '1', style: { fontSize: 12 } }}
              required
            />
            <FormInputs
              label={t('forgotPassword.lblEmail')}
              icon={<EmailOutlined fontSize="medium" />}
              type="email"
              name="EMAIL"
              error={error && !!error.email}
              value={dataForm.EMAIL}
              helperText={error && error.email ? error.email : null}
              errorPosition={'105px'}
              errorTopPosition={'-7%'}
              setValue={(e) => handleUpdate('EMAIL', e)}
              inputProps={{ tabIndex: '2', style: { fontSize: 12 } }}
              required
            />
            <FormInputs
              label={t('forgotPassword.lblCdCust')}
              icon={<Image src={IMAGES.ICON.BRANCH.BUILDING} className="icon-deactive" />}
              type="text"
              name="CD_CUST"
              value={dataForm.CD_CUST}
              setValue={(e) => handleUpdate('CD_CUST', e)}
              inputProps={{ tabIndex: '3', style: { fontSize: 12 } }}
              required
            />
            <FormInputs
              label={t('forgotPassword.lblPhone')}
              icon={<Image src={IMAGES.ICON.PHONE} alt="phone Icon" className="icon-deactive" />}
              type="text"
              name="NU_TEL"
              value={dataForm.NU_TEL}
              setValue={(e) => handleUpdate('NU_TEL', e)}
              inputProps={{ tabIndex: '4', style: { fontSize: 12 } }}
              required
              onlyNumber
            />
            <div className="form-forgot__button">
              <div className="btn-submit-container">
                <Button className="btn-submit" type="submit" onClick={handleSubmit} tabIndex="3" disabled={!canSubmit}>
                  <span className="btn-submit-text">{t('forgotPassword.btnSubmit')}</span>
                </Button>
              </div>
              <div className="btn-cancel-container">
                <Button className="btn-cancel" onClick={handleCancel} tabIndex="5">
                  <span className="btn-cancel-text">{t('forgotPassword.btnCancel')}</span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </div>
  )
}

export default ForgotPassword

import axios from 'commons/baseUrl'
import {
  CLEAR_DATA_DOWNLOAD,
  GET_DATA_DOWNLOAD_LIST_FAILED,
  GET_DATA_DOWNLOAD_LIST_REQUEST,
  GET_DATA_DOWNLOAD_LIST_SUCCESS,
  UPDATE_DATA_DOWNLOAD_FAILED,
  UPDATE_DATA_DOWNLOAD_REQUEST,
  UPDATE_DATA_DOWNLOAD_SUCCESS,
} from 'constants/actionTypes/dataDownloadActionTypes'
import { API_GET_DATA_DOWNLOAD, API_UPDATE_DATA_DOWNLOAD } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getDataDownloadAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_DATA_DOWNLOAD_LIST_REQUEST })
    const res = await axios.get(API_GET_DATA_DOWNLOAD, { params })
    // const res = {
    //   data: {
    //     data: [
    //       {
    //         DT_REGIST: '2022-03-03',
    //         KY_PARTS: '01313BPJUTTO21111801T.txt',
    //         DT_WAIT: '2022-03-03 19:13:37',
    //         KY_SRC: '01313BUPPINTO',
    //         ID_DATA: 'BPJUT',
    //         NM_FILE_DSP: '物品受領情報Ｔ統ド21年11月18日01回',
    //         NM_DISP_FLD: '物品受領情報',
    //         CD_DATA_DOWNLOAD: 'TO',
    //         DT_UPDATE: '2022-03-03 19:12:20.231',
    //         ID_FOLDER: 'BUPPIN',
    //         NU_DL_COUNT: '4',
    //         NU_SIZE: '1338',
    //         NM_DATA_DOWNLOAD: '統ド',
    //         NM_DATA: '物品受領情報Ｔ',
    //         KB_FL_FORMAT: '1',
    //         ID_FILE: 'BPJUTTO21111801T.txt',
    //         KB_CYCLE: '3',
    //         NM_CUST: 'データコムテスト２',
    //         CD_CUST: '01313',
    //         DT_DL_WAIT: '2022-03-03 20:12:37',
    //         TM_REGIST: '19:12:37',
    //         YM_REGIST: '2022-03',
    //         KB_DL: '0',
    //       },
    //       {
    //         DT_REGIST: '2022-03-04',
    //         KY_PARTS: '01313KIKANAS20220104T.txt',
    //         DT_WAIT: '2022-03-04 10:36:49',
    //         KY_SRC: '01313KIKAKUANNAIAS',
    //         ID_DATA: 'KIKAN',
    //         NM_FILE_DSP: '企案５週前Ｐ青セ2022年01月04週',
    //         NM_DISP_FLD: '企画案内',
    //         DT_UPDATE: '2022-03-04 10:30:40.665',
    //         CD_DATA_DOWNLOAD: 'AS',
    //         NU_DL_COUNT: '1',
    //         ID_FOLDER: 'KIKAKUANNAI',
    //         NU_SIZE: '319',
    //         NM_DATA_DOWNLOAD: '青セ',
    //         NM_DATA: '企案５週前Ｐ',
    //         KB_FL_FORMAT: '2',
    //         ID_FILE: 'KIKANAS20220104T.txt',
    //         KB_CYCLE: '2',
    //         NM_CUST: 'データコムテスト２',
    //         CD_CUST: '01313',
    //         TM_REGIST: '10:31:49',
    //         DT_DL_WAIT: '2022-03-04 11:31:49',
    //         YM_REGIST: '2022-03',
    //         KB_DL: '0',
    //       },
    //     ],
    //     STS: 0,
    //     error: false,
    //     message: 'success',
    //   },
    // }
    if (!res?.data || res?.data?.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_DATA_DOWNLOAD_LIST_FAILED, payload: res?.data?.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res?.data) {
      dispatch({ type: GET_DATA_DOWNLOAD_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_DATA_DOWNLOAD_LIST_FAILED, payload: err.message })
  }
}

const updateDataDownloadAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DATA_DOWNLOAD_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_DATA_DOWNLOAD, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_DATA_DOWNLOAD_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_DATA_DOWNLOAD_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_DATA_DOWNLOAD_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_DATA_DOWNLOAD_FAILED, payload: err.message })
  }
}

const clearDataDownload = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DATA_DOWNLOAD, payload: '' })
}

export { clearDataDownload, getDataDownloadAction, updateDataDownloadAction }

import { IMAGES } from 'assets'
import React from 'react'

const BaseImage = ({ src, style, alt, ...others }) => {
  const ims = React.useMemo(() => src || IMAGES.DEFAULT_IMAGE, [src])

  return (
    <img
      src={ims}
      onError={(e) => {
        e.target.src = IMAGES.DEFAULT_IMAGE
        e.target.alt = 'no_image'
        e.target.onerror = null
      }}
      style={style}
      alt={alt}
      {...others}
    />
  )
}

export default React.memo(BaseImage)

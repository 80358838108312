import './ToolBar.css'

import { Button } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IMAGES } from 'assets'
import {
  checkExistedFileInS3,
  formatDateWithPadding,
  formatDtweekOptions,
  getPreSignedUrlS3,
  handleDownload2,
  handleDownloadSingleFileDataDownload,
} from 'commons/utilities'
import { CustomCheckbox, Image, SearchCalendar, SearchFormInput, SelectInput } from 'components/commons'
import { TOOLBAR_TYPE } from 'constants/constant'
import React from 'react'
import { useTranslation } from 'react-i18next'

import filterIcon from '../../../assets/img/filter-solid.svg'
import arrowRight from '../../../assets/img/play-solid.svg'
import createIcon from '../../../assets/img/plus-solid.svg'
import SelectInputCalendar from '../FormInputs/SelectInputCalendar'
import InformationSharpCornerTop from '../InfomationSharpCornerTop/InfomationSharpCornerTop'
import styles from './ToolBarStyles'

function ToolBar({
  listToolBarComponent = [],
  setShowModal,
  handleCreateOnclick,
  setSearchData,
  setSearchInput,
  searchInput,
  handleSubmit,
  setFilterItem,
  filterItem,
  filterLabel,
  setSelectedDate,
  handleFilterDate,
  selectedDate = formatDateWithPadding(new Date(), 'yyyy-MM-dd'),
  // headerCsvLabel,
  // headerCsvKey,
  // listData,
  // fileCsvName = '',
  // confirmEdit,
  // csvButtonStyle = false,
  setSelectedEndDate,
  handleFilterEndDate,
  selectedEndDate = formatDateWithPadding(new Date(), 'yyyy-MM-dd'),
  canDownloadData = false,
  downloadParams,
  toolbarWidth = ' ',
  customCheckboxClassName = '',
  setIsValidDate,
  setIsInDateRange,
  approvalListItems,
  hqConfirmListItems,
  handleUpdateApproval,
  handleUpdateHqConfirm,
  handleUpdateCompliance,
  complianceKbEdit,
  approvalItem,
  hqConfirmItem,
  listFilterItem,
  toolbarNoHeight = false,
  toolbarNoHeightPaddingTop = false,
  weekInputs = false,
  handleSelectFolderName,
  undownloaded = true,
  setUndownloaded,
  handleFilterByUndownloaded,
  screen,
  handleDowloadCsv,
  handleGoBack,
  batchDownload = false,
  chooseAll = false,
  listData,
  selectAll,
  handleSelectAll,
  listChoosenFolderFile,
  cdCust,
  updateDataDownload,
  filterItemMonth,
  filterItemYear,
  listFilterItemMonth,
  listFilterItemYear,
  setFilterItemMonth,
  setFilterItemYear,
  filterItemDtWeek,
  setFilterItemDtWeek,
  listDtWeekOption,
  setFilterItemYmRegist,
  filterItemYmRegist,
  listYmRegistOption,
  listYmRegist,
  setDownloadErrorMessage,
  setNotExistFileInS3Error,
}) {
  const { t, i18n } = useTranslation()

  const classes = styles()

  const ArrowRight = () => (
    <img
      src={arrowRight}
      alt="ArrowRight Icon"
      className="icon-redirect"
      onClick={() => {
        setShowModal(true)
      }}
    />
  )
  const CreateIcon = () => (
    <img src={createIcon} alt="Create Icon" className="icon-redirect" onClick={handleCreateOnclick} />
  )

  const FilterIcon = () => <img src={filterIcon} alt="Filter Icon" className="icon-filter" />

  const getToolBarComponent = (toolbar = {}, index = 0) => {
    let component = null
    switch (toolbar.type) {
      /* PREVIEW */
      case TOOLBAR_TYPE.PREVIEW:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <ArrowRight />
          </div>
        )
        return component
      /* CREATE */

      case TOOLBAR_TYPE.ADD:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <CreateIcon />
          </div>
        )
        return component
      /* DOWNLOAD */

      case TOOLBAR_TYPE.DOWNLOAD:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <Image src={IMAGES.ICON.DOWNLOAD_ICON} className="icon-action" onClick={() => {}} />
          </div>
        )
        return component
      /* CHECKBOX */

      case TOOLBAR_TYPE.CHECKBOX:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <CustomCheckbox
              label={t('dataDownload.lblNotDownloaded')}
              checked={Boolean(Number(undownloaded))}
              onChange={(e) => {
                setUndownloaded(Number(e))
                handleFilterByUndownloaded(Number(e))
              }}
              className={`checkbox ${customCheckboxClassName}`}
            />
          </div>
        )
        return component

      case TOOLBAR_TYPE.CHECKBOX_DISABLE:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <CustomCheckbox
              label={t('downloadStatus.lblNotDownloaded')}
              checked={Boolean(Number(undownloaded))}
              disabled={true}
              onChange={(e) => {
                setUndownloaded(Number(e))
                handleFilterByUndownloaded(Number(e))
              }}
              className="checkbox"
            />
          </div>
        )
        return component
      /* FILTER FIELD */

      case TOOLBAR_TYPE.FILTER_LOGO:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <FilterIcon />
            {filterLabel ? (
              <span className="toolbar-text filterWeek-label">{filterLabel}</span>
            ) : (
              <span className="toolbar-text">{t('commonComponent.toolBar.lblFilterText')}</span>
            )}
          </div>
        )
        return component
      /* SEARCH */

      case TOOLBAR_TYPE.SEARCH:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className={`${toolbar.componentStyle}`}>
              <SearchFormInput
                setSearchData={setSearchData}
                setSearchInput={setSearchInput}
                searchInput={searchInput}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        )
        return component

      /* TURN_BACK */
      case TOOLBAR_TYPE.TURN_BACK:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className={`${toolbar.componentStyle}`}>
              <ArrowBackIcon
                onClick={() => {
                  handleGoBack()
                }}
                className="arrow-back-icon"
              />
            </div>
          </div>
        )
        return component

      case TOOLBAR_TYPE.SEARCH_LEFT:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className={`${toolbar.componentStyle}`}>
              <SearchFormInput
                setSearchData={setSearchData}
                setSearchInput={setSearchInput}
                searchInput={searchInput}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        )
        return component
      /* SELECT DATE */

      case TOOLBAR_TYPE.FILTER_DATE:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <SelectInput
              itemType={'filter'}
              onChange={(v) => setFilterItem(v.target.value)}
              value={filterItem}
              items={[
                { value: t('commonComponent.toolBar.lblStartDate'), label: t('commonComponent.toolBar.lblStartDate') },
                { value: t('commonComponent.toolBar.lblEndDate'), label: t('commonComponent.toolBar.lblEndDate') },
              ]}
              icon={''}
              name="select_item--toolbar"
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 10,
                  horizontal: 0,
                },
                getContentAnchorEl: null,
              }}
            />
          </div>
        )
        return component

      case TOOLBAR_TYPE.FOLDER_SELECT:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <SelectInput
              itemType={'filter'}
              onChange={(e) => {
                setFilterItem(e.target.value)
                handleSelectFolderName(e.target.value)
              }}
              value={filterItem}
              items={listFilterItem}
              icon={''}
              name="select_item--toolbar"
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 10,
                  horizontal: 0,
                },
                getContentAnchorEl: null,
              }}
            />
          </div>
        )
        return component

      /* CALENDAR */

      case TOOLBAR_TYPE.CALENDAR:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className={`${toolbar.componentStyle}`}>
              <SearchCalendar
                inputVariant="outlined"
                formatDate="yyyy-MM-dd"
                setIsValidDate={setIsValidDate}
                setIsInDateRange={setIsInDateRange}
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(formatDateWithPadding(e, 'yyyy-MM-dd'))
                }}
                PopoverProps={{
                  anchorOrigin: { horizontal: 0, vertical: 'bottom' },
                  transformOrigin: { horizontal: 0, vertical: 8 },
                }}
                onSubmit={(date) => {
                  handleFilterDate(date)
                }}
              />
            </div>
          </div>
        )
        return component

      /* END CALENDAR */

      case TOOLBAR_TYPE.END_CALENDAR:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <span className="toolbar-to-lbl">{t('toolbar.lblToText')}</span>
            <div className={`${toolbar.componentStyle}`}>
              <SearchCalendar
                inputVariant="outlined"
                formatDate="yyyy-MM-dd"
                setIsValidDate={setIsValidDate}
                setIsInDateRange={setIsInDateRange}
                value={selectedEndDate}
                onChange={(e) => {
                  setSelectedEndDate(formatDateWithPadding(e, 'yyyy-MM-dd'))
                }}
                PopoverProps={{
                  anchorOrigin: { horizontal: 0, vertical: 'bottom' },
                  transformOrigin: { horizontal: 0, vertical: 8 },
                }}
                onSubmit={(date) => {
                  handleFilterEndDate(date)
                }}
              />
            </div>
          </div>
        )
        return component

      case TOOLBAR_TYPE.WEEK_CALENDAR:
        component = (
          <div key={index}>
            <div className="dt-week-calendar-container ">
              <div className="year-month-calendar-item">
                <SelectInputCalendar
                  itemType={'filter'}
                  onChange={(e) => {
                    setFilterItemDtWeek(e.target.value)
                    handleFilterDate(e)
                  }}
                  value={filterItemDtWeek}
                  items={listDtWeekOption.map((data) => ({
                    ...data,
                    label: formatDtweekOptions(data.label),
                  }))}
                  icon={''}
                  name="select_item--toolbar"
                  variant="outlined"
                  inputProps={{ className: 'item-year' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 10,
                      horizontal: 0,
                    },
                    getContentAnchorEl: null,
                  }}
                />
              </div>
            </div>
          </div>
        )
        return component
      /*YEAR_MONTH_CALENDAR */
      case TOOLBAR_TYPE.YEAR_MONTH_CALENDAR:
        component = (
          <div key={index}>
            <div className="year-month-calendar-container">
              <div className="year-month-calendar-item">
                <SelectInputCalendar
                  itemType={'filter'}
                  onChange={(e) => {
                    setFilterItemYmRegist(e.target.value)
                    handleFilterDate(e)
                  }}
                  value={filterItemYmRegist}
                  items={listYmRegistOption}
                  icon={''}
                  name="select_item--toolbar"
                  variant="outlined"
                  inputProps={{ className: 'item-year' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 10,
                      horizontal: 0,
                    },
                    getContentAnchorEl: null,
                  }}
                />
              </div>
            </div>
          </div>
        )
        return component
      /*EXPIRE_DATE */
      case TOOLBAR_TYPE.EXPIRE_DATE:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <span className="toolbar-text">{t('headquarter.lblExpiredDate')}</span>
          </div>
        )
        return component

      /*SENDING_DATE */
      case TOOLBAR_TYPE.SENDING_DATE:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <span className="toolbar-text">{t('dataDownload.lblSendingDate')}</span>
          </div>
        )
        return component

      /*TIME_LABEL */
      case TOOLBAR_TYPE.TIME_LABEL:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <span className="timelablel-text">{t('portal.lbl_timeStamp')}</span>
          </div>
        )
        return component
      /* COMPLIANCE_CHECKBOX */
      case TOOLBAR_TYPE.COMPLIANCE_CHECKBOX:
        component = (
          <div key={index}>
            <CustomCheckbox
              label={t('compliance.lblCorrectionCheckbox')}
              checked={Boolean(Number(complianceKbEdit))}
              onChange={(e) => handleUpdateCompliance('KB_EDIT', e)}
              className="checkbox"
            />
          </div>
        )
        return component
      /* APPROVAL_SELECT */
      case TOOLBAR_TYPE.APPROVAL_SELECT:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className="toolbar-text apporval-label">{t('compliance.lblApproval')}</div>
            <SelectInput
              itemType={'filter'}
              onChange={(v) => handleUpdateApproval(v.target.value)}
              value={approvalItem}
              items={approvalListItems}
              icon={''}
              name="select_item--toolbar"
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 10,
                  horizontal: 0,
                },
                getContentAnchorEl: null,
              }}
            />
          </div>
        )
        return component
      /* HEAD_QUARTERS_CONFIRM_SELECT */
      case TOOLBAR_TYPE.HEAD_QUARTERS_CONFIRM_SELECT:
        component = (
          <div className={`${toolbar.commonStyle}`} key={index}>
            <div className="toolbar-text hqConfirm-label">{t('compliance.lblHeadQuarterConfirm')}</div>
            <SelectInput
              itemType={'filter'}
              onChange={(v) => handleUpdateHqConfirm(v.target.value)}
              value={hqConfirmItem}
              items={hqConfirmListItems}
              icon={''}
              name="select_item--toolbar"
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 10,
                  horizontal: 0,
                },
                getContentAnchorEl: null,
              }}
            />
          </div>
        )
        return component
      default:
        return ''
    }
  }
  const handleDownloadFile = async (folder, file, date) => {
    const errorMessage = t('commonComponent.notExistFileInS3')
    const fileName = file
    const filePath = folder + file
    const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
    const params = {
      bucket: bucketName,
      filePath: filePath,
      dtResist: date,
    }

    const res = await checkExistedFileInS3(params)
    if (!res.data) {
      setNotExistFileInS3Error(errorMessage)
    } else {
      setNotExistFileInS3Error('')
      const fileUrl = await getPreSignedUrlS3(bucketName, filePath)
      await handleDownloadSingleFileDataDownload(fileUrl, fileName, updateDataDownload(file, date))
    }
  }

  const handleBatchDownload = async (listChoosenFolderFile) => {
    const fileList = []
    listChoosenFolderFile.forEach((folder) => {
      if (folder?.list_file.length > 0) {
        folder.list_file.forEach((file) => {
          fileList.push({ id_folder: file.ID_FOLDER, id_file: file.ID_FILE, dt_regist: file.DT_REGIST })
        })
      }
    })
    for (let index = 0; index < fileList.length; index++) {
      await handleDownloadFile(fileList[index].id_folder, fileList[index].id_file, fileList[index].dt_regist)
    }
  }

  return (
    <div
      className={`${toolbarNoHeight === true ? 'toolbar-no-height' : 'toolbar'} ${
        toolbarNoHeightPaddingTop === true ? 'toolbar-no-height-padding-top' : ''
      } ${toolbarWidth === 'SMALL' ? 'toolbar-small' : ''}`}
    >
      {chooseAll && (
        <div className="choose-all-container">
          <Button
            variant="outlined"
            color="success"
            className="choose-all"
            onClick={(e) => handleSelectAll(listData, selectAll)}
          >
            {' '}
            {selectAll ? `${t('toolbar.lblUncheckAll')}` : `${t('toolbar.lblCheckAll')}`}
          </Button>
        </div>
      )}

      {batchDownload && (
        <>
          <div className="btn-download-all-container">
            <Button
              variant="contained"
              className="btn-download-all"
              onClick={(e) => handleBatchDownload(listChoosenFolderFile)}
            >
              {t('toolbar.lblBatchDownload')}
            </Button>
          </div>
          <div className="infomation-container">
            <InformationSharpCornerTop
              icon={<Info className={classes.iconInfo} />}
              message={t('dataDownload.batchDownloadInfo')}
            />
          </div>
        </>
      )}

      {/* Action */}
      <div className="actionContainer">
        {listToolBarComponent.map((toolbar, index) => getToolBarComponent(toolbar, index))}
      </div>
      {/* Csv Dowload */}
      {canDownloadData && (
        <div className={`csv-container`}>
          {listToolBarComponent[listToolBarComponent.length - 1].type === TOOLBAR_TYPE.CSV && (
            <div className="btn-download">
              <Button
                variant="contained"
                // color="rgb(85, 130, 50)"
                onClick={
                  screen === t('compliance.lblScreenName')
                    ? handleDowloadCsv
                    : () => handleDownload2(downloadParams, i18n)
                }
              >
                {t('commonComponent.buttonDowload.btnDownload')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ToolBar

import './Footer.css'

import Grid from '@material-ui/core/Grid'
import React from 'react'

function Footer() {
  return (
    <footer className="footer">
      <Grid>
        <p className="copyright">
          &copy; {new Date().getFullYear()} <span>DATACOM Corp.</span>
        </p>
      </Grid>
    </footer>
  )
}

export default Footer

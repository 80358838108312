import 'date-fns'
import './Calendar.css'

import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { IMAGES } from 'assets'
import { formatDateWithPadding, isInDateRange, isValidDate, isWeekInvalid } from 'commons/utilities'
import { getDateInfoSystem } from 'commons/utilities'
import { Image } from 'components/commons'
import localeJa from 'date-fns/locale/ja'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
class CustomDateUtils extends DateFnsUtils {
  getYearText(date) {
    return date.getFullYear() + '年'
  }
  getCalendarHeaderText(date) {
    return date.getFullYear() + '年' + (date.getMonth() + 1) + '月'
  }

  getDatePickerHeaderText(date) {
    const result = `${date.getMonth() + 1}月${date.getDate()}日`
    return result
  }
}

const getDate = (date, dateFormat = 'yyyy-MM-dd') => {
  const myDate = formatDateWithPadding(date, dateFormat)
  return myDate
}

const CustomCalendar = ({
  value,
  onChange,
  setIsValidDate,
  setIsInDateRange,
  formatDate = 'yyyy-MM-dd',
  viewsItem,
  openItem,
  icon = null,
  tabIndex,
  onSubmit,
  monthInputs = false,
  weekInputs = false,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(80,80,80,0.9)',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(80,80,80,0.9)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(85, 130, 50)',
      },
      '& .MuiTextField-root': {
        '& .MuiInputLabel-formControl.Mui-required': {
          '&::after': {
            content: '"*"',
            background: '#fff',
            color: 'red',
            // borderRadius: 4,
            // border: '1px solid red',
            // padding: '2px 6px',
            fontSize: 20,
            margin: 3,
            marginRight: 20,
            position: 'absolute',
            width: 'max-content',
            top: -5,
            left: 'calc(100% + 5px)',
          },
          '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
            display: 'none',
          },
        },
      },
      minWidth: 105,
    },
    inputStyle: {
      color: 'rgb(85, 130, 50)',
      fontSize: '12px',
    },
  }))
  localeJa.options.weekStartsOn = 0
  const dtDeadl = getDateInfoSystem()
  const classes = useStyles()
  const dateRef = useRef()
  const weekError = useRef()
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(value)
  const [isSelected, setIsSelected] = useState(false)
  const [readOnly, setReadOnly] = useState(true)
  const IconCalendar = () => <Image src={IMAGES.ICON.CALENDAR_ALT} style={{ width: 16 }} />

  const handleDateChange = useCallback(
    (date, type) => {
      weekError.current = false
      // update select date
      if (typeof onChange === 'function') onChange(getDate(date))

      // set onchange date to listener event enter
      if (getDate(date) !== dateRef.current) setIsSelected(true)

      setSelectedDate(getDate(date))
      // handle update last date
      dateRef.current = getDate(date)
      if (!isValidDate(dateRef.current)) {
        setIsValidDate(false)
      } else {
        setIsValidDate(true)
      }
      if (!isInDateRange(dateRef.current, dtDeadl.DT_DSTART, dtDeadl.DT_DEND)) {
        setIsInDateRange(false)
      } else {
        setIsInDateRange(true)
      }
      // handle error for week inputs
      if (
        weekInputs &&
        isValidDate(dateRef.current) &&
        isInDateRange(dateRef.current, dtDeadl.DT_DSTART, dtDeadl.DT_DEND)
      ) {
        if (isWeekInvalid(dateRef.current)) {
          weekError.current = true
        }
      }
      if (type) {
        if (dateRef.current === selectedDate) return // equals to prev date then no actions
        if (moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD') !== selectedDate) return
        if (typeof onSubmit === 'function') onSubmit(dateRef.current)
        setIsSelected(false) // close modal then reset this value
      }
    },
    [selectedDate, onChange, dateRef, onSubmit]
  )
  const handleUserKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13 && isSelected) {
        setReadOnly(true)
        setIsSelected(false) // close modal then reset this value
        if (!document.querySelector('.MuiPopover-root .MuiPickersBasePicker-container')) return
        // eslint-disable-next-line
        if (selectedDate.toString() != 'Invalid Date') {
          if (typeof onSubmit === 'function') onSubmit(selectedDate)
        }
      }
    },
    [isSelected, selectedDate, onSubmit]
  )

  // event keydown to confirm date
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  useEffect(() => {
    dateRef.current = value
  }, [value])

  return (
    <div className={`${classes.root} calendar-container`} style={{ position: 'relative' }}>
      <MuiPickersUtilsProvider locale={localeJa} utils={CustomDateUtils}>
        <KeyboardDatePicker
          format={formatDate}
          variant="inline"
          orientation="portrait"
          value={value}
          views={viewsItem}
          openTo={openItem}
          onChange={(date) => handleDateChange(date)}
          keyboardIcon={<IconCalendar />}
          autoOk={true}
          KeyboardButtonProps={
            monthInputs || weekInputs
              ? { disabled: true, style: { display: 'none' } }
              : {
                  'aria-label': 'change date',
                }
          }
          minDate={dtDeadl.DT_DSTART}
          maxDate={dtDeadl.DT_DEND}
          // minDateMessage={`日付は${dtDeadl.DT_DSTART}以降の日付を入力してください`}
          // maxDateMessage={`日付は${dtDeadl.DT_DEND}以下の日付を入力してください`}
          minDateMessage={t('validate.calendar.msgMinDate', { targetDate: dtDeadl.DT_DSTART })}
          maxDateMessage={t('validate.calendar.msgMaxDate', { targetDate: dtDeadl.DT_DEND })}
          // close calendar then set date
          onClose={() => handleDateChange(dateRef.current, 'close')}
          focused={!readOnly}
          InputProps={{
            tabIndex: tabIndex,
            readOnly: readOnly,
            onKeyDown: (event) => {
              const { key } = event
              if (key === 'Enter') {
                event.preventDefault(event)
                setReadOnly(true)
                if (moment(dateRef.current, 'YYYY-MM-DD').format('YYYY-MM-DD') !== dateRef.current) return
                // eslint-disable-next-line
                if (selectedDate.toString() == 'Invalid Date') return
                if (typeof onSubmit === 'function' && isSelected) onSubmit(dateRef.current)
              }
            },
            onClick: () => {
              setReadOnly(false)
            },
            onFocus: () => {
              setReadOnly(false)
            },
            onBlur: () => {
              setReadOnly(true)
            },
          }}
          // invalidDateMessage={isDateInvalid ? '入力された日付が不正です' : ''}
          invalidDateMessage={weekInputs ? t('compliance.lblInvalidDate') : t('validate.calendar.msgInvalidDate')}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      <div className={`${weekError.current ? 'weekInput-err' : 'weekInput-err-hide'}`}>
        {t('compliance.lblErrorWrongWeek')}
      </div>
    </div>
  )
}

export default CustomCalendar

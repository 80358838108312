import './FilePreviewer.css'

import { Box, Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import FileViewer from 'react-file-viewer'
import { useTranslation } from 'react-i18next'

import MessageErrorDialog from '../MessageErrorDialog/MessageErrorDialog'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

export default function FilePreviewer({ file, type, preview, setPreview, filename }) {
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const [messageError, setMessageError] = useState(false)
  const supportedOfficeExtensions = ['xlsx', 'docx']
  const supportedExtensionByDocViewer = ['pdf', 'txt']
  const handleClose = () => setPreview(false)
  const isSupportedOfficeFile = () => {
    return supportedOfficeExtensions.includes(type)
  }
  const isSupportedByDocViewer = () => {
    return supportedExtensionByDocViewer.includes(type)
  }

  const ReadFileTxt = (file) => {
    async function fetchData() {
      var xhr = new XMLHttpRequest()

      xhr.open('GET', file, true)

      xhr.responseType = 'blob'
      xhr.onload = function (e) {
        if (this.status === 200) {
          var myBlob = new Blob([this.response], { type: 'text/plain' })
          var reader = new FileReader()
          reader.onload = function (event) {
            setData(event.target.result.replace(/\r/g, '<br/>'))
          }

          // myBlob is now the blob that the object URL pointed to.
          reader.readAsText(myBlob)
        }
      }

      xhr.send()
    }

    fetchData()
  }

  const renderElementByType = (file, type) => {
    if (isSupportedOfficeFile()) {
      return <FileViewer fileType={type} filePath={file} style={{ width: '100%', height: '100%', fontSize: 12 }} />
    }
    if (type.toString() === 'txt') {
      ReadFileTxt(file)
    } else if (isSupportedByDocViewer()) {
      return (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: file, fileType: type }]}
          style={{ width: '100%', height: '100%', fontSize: 12 }}
        />
      )
    }
  }

  useEffect(() => {
    if (!isSupportedOfficeFile() && !isSupportedByDocViewer()) {
      setMessageError(`<div>${t('validate.unSupportedExtension.line1')}</div>
      <div>${t('validate.unSupportedExtension.line2')}</div>`)
    }
  }, [file])

  return !isSupportedOfficeFile() && !isSupportedByDocViewer() ? (
    messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />
  ) : (
    <div id="Modal__preview-file--wrapper">
      <Modal open={preview} id="Modal__preview-file" onClose={handleClose}>
        <Box sx={style}>
          {/* <div>{file}</div> */}
          {renderElementByType(file, type)}

          {data && type === 'txt' && (
            <div className="iframe--previewFile__txt">
              <div>{typeof filename === 'object' ? filename.name : filename}</div>
              <div className={'background-file'} dangerouslySetInnerHTML={{ __html: data }}></div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  )
}

import {
  CLEAR_DATA,
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'

function forgotPasswordReducer(state = { data: {} }, action) {
  switch (action.type) {
    case FORGOT_PASS_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
        type: FORGOT_PASS_REQUEST,
      }
    case FORGOT_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        message: 'success',
        type: FORGOT_PASS_SUCCESS,
      }
    case FORGOT_PASS_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        message: action.payload,
        type: FORGOT_PASS_FAILED,
      }
    case CLEAR_DATA:
      return { type: CLEAR_DATA, loading: false, data: {}, message: '' }
    default:
      return { ...state, loading: false, data: {}, message: '' }
  }
}

export { forgotPasswordReducer }

import './PasswordForm.css'

import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Lock, Visibility, VisibilityOff } from '@material-ui/icons'
import { SCREEN_NAME } from 'constants/constant'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  customInputPasswordBackground: {
    position: 'relative',
  },
  customPaswordMargin: {
    marginTop: '-2px',
  },
}))
const theme = createTheme({
  palette: {
    primary: green,
  },
})

function PasswordFormInput(props) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const customClassPasswordInput = props.customClassPasswordInput ? props.customClassPasswordInput : ''
  return (
    <div
      className={`content ${
        props.screen === SCREEN_NAME.MYPAGE || props.screen === SCREEN_NAME.CHANGE_PASSWORD
          ? classes.customInputPasswordBackground
          : ''
      } `}
    >
      <FormControl className={`${classes.textField} ${customClassPasswordInput}`}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item className="wrap-lock-icon">
            <Lock style={{ color: green[500], fontSize: 24 }} />
          </Grid>
          <Grid item>
            <ThemeProvider theme={theme}>
              <InputLabel htmlFor="standard-adornment-password" style={{ color: green[500], paddingLeft: 41 }}>
                <div className="changePass-label">
                  <div className={classes.customPaswordMargin}>{props.label}</div>
                  {props.required && <div className="changePass-labelEnd">*</div>}
                </div>
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={props.password}
                inputProps={{ tabIndex: props.tabIndex }}
                onChange={(e) => {
                  props.setPassword(e.target.value)
                  if (!e.target.value) {
                    props.setErrorMessage('')
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      tabIndex={-1}
                      className="icon-visibility backgorund-password-hover"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </FormControl>
      {props.errorMessage && (
        <p
          className={`error-message ${
            props.label === t('changePassword.lblNewPasswordConfirm') ||
            props.label === t('changePassword.lblPassWordReEnter')
              ? 'left140'
              : ''
          } `}
        >
          {props.errorMessage}
        </p>
      )}
    </div>
  )
}

export default PasswordFormInput

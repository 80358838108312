import {
  ALLERGEN_CLASSFICATION_CODE,
  COMPLIANCE_TYPE,
  KONTAMI_CLASSFICATION_CODE,
  LIST_COMPLIANCE_TYPE,
} from 'constants/constant'
import React from 'react'

import RadioCommon from '../Radio/RadioCommon'

function Allergen({
  customClass,
  title,
  radioValue,
  listAllergenArray,
  lastArrayIndex,
  lblNotHaveAllergen,
  lblAllergenNoApply,
  lblAllerenNotSet,
  allergenBefore = true,
  kontamiBefore = true,
  handleClick = false,
  before = true,
  handleChangeCodeAF,
  complianceType = '',
  type = '',
}) {
  const getAllergenColorStyle = (allergen) => {
    switch (complianceType) {
      case LIST_COMPLIANCE_TYPE.ALLERGEN_BEFORE:
        if (radioValue === ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN && !allergen.DISABLE_BEFORE) {
          return 'allergen-bf-code flex-center'
        }
        return 'allergen-bf-code-no-check flex-center'

      case LIST_COMPLIANCE_TYPE.ALLERGEN_AFTER:
        if (radioValue === ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN && !allergen.DISABLE_AFTER) {
          return 'allergen-af-code flex-center'
        }
        return 'allergen-af-code-no-check flex-center'

      case LIST_COMPLIANCE_TYPE.KONTAMI_BEFORE:
        if (radioValue === KONTAMI_CLASSFICATION_CODE.HAVE_KONTAMI && !allergen.KONTAMI_DISABLE_BEFORE) {
          return 'allergen-bf-code flex-center'
        }
        return 'allergen-bf-code-no-check flex-center'

      case LIST_COMPLIANCE_TYPE.KONTAMI_AFTER:
        if (radioValue === KONTAMI_CLASSFICATION_CODE.HAVE_KONTAMI && !allergen.KONTAMI_DISABLE_AFTER) {
          return 'allergen-af-code flex-center'
        }
        return 'allergen-af-code-no-check flex-center'

      default:
        return ''
    }
  }

  return (
    <div
      className={`${
        type === COMPLIANCE_TYPE.KONTAMI
          ? kontamiBefore
            ? 'allergenContainer allergenBefore'
            : 'allergenContainer allergenAfter'
          : allergenBefore
          ? 'allergenContainer allergenBefore'
          : 'allergenContainer allergenAfter'
      }`}
    >
      <div className="title">
        <div
          className={`title__Text ${
            complianceType === LIST_COMPLIANCE_TYPE.ALLERGEN_AFTER ||
            complianceType === LIST_COMPLIANCE_TYPE.KONTAMI_AFTER
              ? 'fontWeightBold'
              : ''
          }`}
        >
          {title}
        </div>
      </div>
      <div className={`content ${customClass ? customClass : ''}`}>
        <div className="content__row1">
          <div className="radio">
            <div className="radio__container">
              <RadioCommon
                checked={radioValue === ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN}
                value={ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN}
                handleChangeCodeAF={handleChangeCodeAF}
                before={before}
              />
            </div>
          </div>
          <div>
            <div style={{ width: '100%', height: 'auto', display: 'flex' }}>
              <div>
                {/* ALLERGEN WITH ATTRIBUTE KB_SPEC = 0 */}
                {Object.keys(listAllergenArray).length > 0
                  ? Object.values(listAllergenArray)
                      .slice(0, lastArrayIndex + 1)
                      .map((arr) => {
                        return (
                          <div style={{ display: 'flex', maxWidth: '700px', flexWrap: 'wrap' }}>
                            {arr
                              .sort((a, b) => {
                                if (a['NU_DSP'] >= b['NU_DSP']) {
                                  return 1
                                }
                                if (a['NU_DSP'] < b['NU_DSP']) {
                                  return -1
                                }
                                return 0
                              })
                              .map((allergen) => {
                                return (
                                  <div
                                    className={`${getAllergenColorStyle(allergen)}`}
                                    onClick={
                                      typeof handleClick === 'function' && before === false
                                        ? () => handleClick(allergen, 0)
                                        : () => {}
                                    }
                                  >
                                    {allergen.NM_ALRG_DSP}
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })
                  : ''}
                <div style={{ display: 'flex', maxWidth: '700px', flexWrap: 'wrap' }}>
                  {/* ALLERGEN WITH ATTRIBUTE KB_SPEC = 1 */}
                  {Object.keys(listAllergenArray).length > 0
                    ? Object.values(listAllergenArray)
                        .slice(lastArrayIndex + 1)
                        .reduce((prev, current, index) => {
                          if (!allergenBefore || !kontamiBefore) {
                            current = current.map((data) => {
                              return { ...data, ...{ INDEX: index + 1 } }
                            })
                            prev.push(...current)
                          } else {
                            prev.push(...current)
                          }
                          return prev
                        }, [])
                        .sort((a, b) => {
                          if (a['NU_DSP'] >= b['NU_DSP']) {
                            return 1
                          }
                          if (a['NU_DSP'] < b['NU_DSP']) {
                            return -1
                          }
                          return 0
                        })
                        .map((allergen) => {
                          return (
                            <div
                              className={`${getAllergenColorStyle(allergen)}`}
                              onClick={
                                typeof handleClick === 'function' && before === false
                                  ? () => handleClick(allergen, allergen.INDEX)
                                  : () => {}
                              }
                            >
                              {allergen.NM_ALRG_DSP}
                            </div>
                          )
                        })
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content__row2">
          <div className="radio">
            <div className="radio__container">
              <RadioCommon
                checked={radioValue === ALLERGEN_CLASSFICATION_CODE.NO_ALLERGEN}
                value={ALLERGEN_CLASSFICATION_CODE.NO_ALLERGEN}
                handleChangeCodeAF={handleChangeCodeAF}
                before={before}
              />
            </div>
          </div>
          <div className="allergen-bf-content">{lblNotHaveAllergen}</div>
        </div>

        <div className="content__row3">
          <div className="radio">
            <div className="radio__container">
              <RadioCommon
                checked={radioValue === ALLERGEN_CLASSFICATION_CODE.NOT_APPLY}
                value={ALLERGEN_CLASSFICATION_CODE.NOT_APPLY}
                handleChangeCodeAF={handleChangeCodeAF}
                before={before}
              />
            </div>
          </div>

          <div className="allergen-bf-content">{lblAllergenNoApply}</div>
        </div>
        <div className="content__row4">
          <div className="radio">
            <div className="radio__container">
              <RadioCommon
                checked={radioValue === ALLERGEN_CLASSFICATION_CODE.NOT_SET}
                value={ALLERGEN_CLASSFICATION_CODE.NOT_SET}
                handleChangeCodeAF={handleChangeCodeAF}
                before={before}
              />
            </div>
          </div>
          <div className="allergen-bf-content">{lblAllerenNotSet}</div>
        </div>
      </div>
    </div>
  )
}

export default Allergen

import axios from 'commons/baseUrl'
import {
  GET_CODE_INFO_FAILED,
  GET_CODE_INFO_REQUEST,
  GET_CODE_INFO_SUCCESS,
} from 'constants/actionTypes/codeActionTypes'
import { API_GET_CODE_INFO } from 'constants/apiPath'

const getCodeInfo = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_CODE_INFO_REQUEST })
    let codeResponse = await axios.get(API_GET_CODE_INFO, { params })
    const result = codeResponse.data.data
    if (Object.keys(result).length > 0) {
      dispatch({ type: GET_CODE_INFO_SUCCESS, payload: result })
    }
  } catch (e) {
    dispatch({ type: GET_CODE_INFO_FAILED, payload: e.message })
  }
}

export default getCodeInfo

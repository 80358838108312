import {
  CHANGE_PASS_FAILED,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
} from 'constants/actionTypes/changePassActionTypes'

// let initialState = {
//   loading: false,
//   message: '',
//   type: CHANGE_PASS_REQUEST,
//   changeInfo: {},
// }
function changePassReducer(state = { password: {} }, action) {
  switch (action.type) {
    case CHANGE_PASS_REQUEST:
      return {
        ...state,
        loading: true,
        password: action.payload,
        type: CHANGE_PASS_REQUEST,
      }
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: { ...action.payload },
        password: { ...action.payload },
        message: 'success',
        type: CHANGE_PASS_SUCCESS,
      }
    case CHANGE_PASS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
        password: {},
        type: CHANGE_PASS_FAILED,
      }
    case CHANGE_PASS_RESET:
      return { type: CHANGE_PASS_RESET, loading: false, status: null, password: {}, message: '' }

    default:
      return state
  }
}

export { changePassReducer }

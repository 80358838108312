import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },

  customCheckbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '10px 20px 0px',
    fontSize: '12px',
    '&. .MuiCheckbox-colorSecondary.Mui-checked:hover,.MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'unset',
    },
    '.myPage_wrapper &': {
      marginTop: '20px',
      marginBottom: '20px',
    },
    '& .MuiCheckbox-root': {
      color: 'unset',
      borderRadius: 0,
      padding: 0,
      marginRight: '12px',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'transparent',
    },
    '& .custom-checkbox': {
      margin: '0 30px !important',
    },
    '& label': {
      height: '20px',
    },
  },
}))

export default styles

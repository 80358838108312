import './ChangePassword.css'

import Button from '@material-ui/core/Button'
import { changePass, resetState } from 'actions/changePassActions'
import { getMyPageAction } from 'actions/myPageActions'
import { IMAGES } from 'assets'
import { getUserInfo, reRenderMessageSplitDot } from 'commons/utilities'
import {
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
} from 'components/commons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import ModalNotification from 'components/commons/ModalNotification/ModalNotification'
import {
  CHANGE_PASS_FAILED,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
} from 'constants/actionTypes/changePassActionTypes'
import { GET_MY_PAGE_FAILED, GET_MY_PAGE_REQUEST, GET_MY_PAGE_SUCCESS } from 'constants/actionTypes/myPageActionTypes'
// import { GET_MY_PAGE_SUCCESS } from 'constants/actionTypes/myPageActionTypes'
import { SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changePasswordValidate } from 'validation/myPageValidate.js'
export default function ChangePassWord() {
  const [error, setError] = useState({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  const userState = useSelector((state) => state.userInfo)
  const { userInfo } = userState
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const [messageError, setMessageError] = useState(false)
  const dispatch = useDispatch()
  const changePassState = useSelector((state) => state.userChangePass)
  const getMyPageReducer = useSelector((state) => state.getMyPage || {})
  // const { hquser } = getMyPageReducer
  const [showProgress, setShowProgress] = useState(false)
  const [showModalNotification, setShowModalNotification] = useState(false)
  // const getMyPageReducer = useSelector((state) => state.getMyPage || {})
  // const { hquser } = getMyPageReducer
  const { loading = false, message = '', type } = changePassState

  const initialDataForm = {
    CD_CUST: userInfo?.data?.CD_CUST || '',
    ID_USER: userInfo?.data?.ID_USER || '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [dataForm, setDataForm] = useState(initialDataForm)

  const checkRequire = React.useCallback(() => {
    if (!dataForm.password || !dataForm.newPassword || !dataForm.confirmPassword || !dataForm.NU_TEL) {
      return false
    } else return true
  }, [dataForm])

  const isErrorChangePassword = (error = {}) => {
    const { errorNewPassword, errorPassword, errorConfirmPassword } = error
    if (errorNewPassword || errorPassword || errorConfirmPassword) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isErrorChangePassword(error) || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, error, checkRequire])

  useEffect(() => {
    switch (getMyPageReducer.type) {
      case GET_MY_PAGE_REQUEST:
        break
      case GET_MY_PAGE_FAILED:
        break
      case GET_MY_PAGE_SUCCESS:
        setDataForm((state) => {
          return {
            ...state,
            NU_TEL: userInfo?.data?.NU_TEL || '',
          }
        })
        break
      default:
        return
    }
    setShowProgress(getMyPageReducer.loading)
  }, [getMyPageReducer])
  useEffect(() => {
    dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
  }, [])

  useEffect(() => {
    // setIsBlocked(false)
    switch (type) {
      case CHANGE_PASS_REQUEST:
        setShowProgress(loading)
        break
      case CHANGE_PASS_SUCCESS:
        setShowProgress(loading)

        resetErrorMessage()
        setShowModalNotification(true)

        dispatch(resetState())
        dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
        break
      case CHANGE_PASS_FAILED:
        setShowProgress(loading)
        setMessageError(message)

        break
      case CHANGE_PASS_RESET:
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassState])

  const redirectToPortal = () => {
    setIsBlocked(false)
    history.push({
      pathname: '/customer/portal',
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // setIsBlocked(false)
    const userInfoDetail = await getUserInfo(userInfo?.data)
    const passwordInDB = userInfoDetail?.ID_PASSW
    if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword) {
      const { currentPasswordError, newPasswordError, confirmPasswordError } = changePasswordValidate(
        passwordInDB,
        dataForm.password,
        dataForm.newPassword,
        dataForm.confirmPassword,
        i18n
      )
      if (currentPasswordError || newPasswordError || confirmPasswordError) {
        const errorPassword = {
          errorPassword: currentPasswordError ? currentPasswordError : undefined,
          errorNewPassword: newPasswordError ? newPasswordError : undefined,
          errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
        }
        setError({
          ...error,
          ...errorPassword,
        })
        return
      }
    }

    setShowModal(true)
    setError()
  }

  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setIsBlocked(true)
    if (name === 'KB_DATA') {
      setDataForm((prev) => ({ ...prev, KB_DATA: (value + 0).toString() }))
      return
    }
    if (name === 'KB_SYSTEM') {
      setDataForm((prev) => ({ ...prev, KB_SYSTEM: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const hideMessageError = (name = '') => {
    if (name === 'password') {
      setError({ ...error, ...{ errorPassword: '' } })
    }
    if (name === 'newPassword') {
      setError({ ...error, ...{ errorNewPassword: '' } })
    }
    if (name === 'confirmPassword') {
      setError({ ...error, ...{ errorConfirmPassword: '' } })
    }
  }

  const resetErrorMessage = () => {
    setError({})
  }

  const handleClearOnClick = () => {
    const initialDataForm = {
      CD_CUST: userInfo?.data?.CD_CUST || '',
      ID_USER: userInfo?.data?.ID_USER || '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      NU_TEL: userInfo?.data?.NU_TEL || '',
    }
    setDataForm(initialDataForm)
    resetErrorMessage()
    checkRequire()
  }

  const submitData = () => {
    const submitData = {
      ...dataForm,
      userName: dataForm.ID_USER,
      oldPassword: dataForm.password,
      newPassword: dataForm.newPassword,
      reEnterPassword: dataForm.confirmPassword,
      cdCust: dataForm.CD_CUST,
      nuTel: dataForm.NU_TEL,
      userInfo: userInfo,
    }
    dispatch(changePass(submitData))
    setIsBlocked(false)
    setShowModal(false)
  }
  const messageConfirm = reRenderMessageSplitDot(
    t('changePassword.msgConfirmSubmission.line1'),
    t('changePassword.msgConfirmSubmission.line2')
  )
  return (
    <div className="change-password-container changepass-container">
      <ModalConfirmNavigation
        title={reRenderMessageSplitDot(
          t('commonComponent.msgConfirmNavigate.line1'),
          t('commonComponent.msgConfirmNavigate.line2')
        )}
        isBlocked={isBlocked}
      />
      {showProgress && <ProgressDialog label={t('changePassword.loading')} />}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {showModalNotification === true && (
        <ModalNotification
          message={t('changePassword.lblChangePasswordSuccess')}
          showModalNotification={setShowModalNotification}
          onClose={redirectToPortal}
        />
      )}
      <ModalConfirm
        title={messageConfirm}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      {showProgress ? (
        <ProgressDialog label={t('myPage.loading')} />
      ) : (
        <div>
          <PasswordFormInput
            password={dataForm.password}
            screen={SCREEN_NAME.CHANGE_PASSWORD}
            setPassword={(e) => handleUpdate('password', e)}
            errorMessage={error?.errorPassword}
            setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
            label={t('changePassword.lblOldPassword')}
            tabIndex="1"
            required={true}
          ></PasswordFormInput>
          <PasswordFormInput
            password={dataForm.newPassword}
            setPassword={(e) => handleUpdate('newPassword', e)}
            errorMessage={error?.errorNewPassword}
            setErrorMessage={(value) => setError({ ...error, ...{ errorNewPassword: value } })}
            label={t('changePassword.lblNewPassword')}
            tabIndex="2"
            required={true}
            screen={SCREEN_NAME.CHANGE_PASSWORD}
          ></PasswordFormInput>
          <PasswordFormInput
            customClassPasswordInput="changePass-labelEnd-last"
            password={dataForm.confirmPassword}
            screen={SCREEN_NAME.CHANGE_PASSWORD}
            setPassword={(e) => handleUpdate('confirmPassword', e)}
            errorMessage={error?.errorConfirmPassword}
            setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
            label={t('changePassword.lblPassWordReEnter')}
            tabIndex="3"
            required={true}
          ></PasswordFormInput>
          {/*NU_TEL */}
          <FormInputs
            id="standard-basic"
            label={t('forgotPassword.lblPhone')}
            value={dataForm.NU_TEL || ''}
            required
            icon={<Image src={IMAGES.ICON.PHONE} alt="phone Icon" className="iconForm-nutel icon--deactive" />}
            type="text"
            name="NU_TEL"
            screen={SCREEN_NAME.CHANGE_PASSWORD}
            inputProps={{ tabIndex: '4' }}
            setValue={(e) => handleUpdate('NU_TEL', e)}
            className="width120"
            errorPosition={'105px'}
            onlyNumber
          />
          <div className="btn-container">
            <Button
              className="button submit-button"
              type="submit"
              variant="contained"
              disabled={!canSubmit}
              tabIndex="5"
              onClick={handleSubmit}
            >
              {t('changePassword.lblChangePasswordConfirm')}
            </Button>
            <Button className="button clear-button" variant="outlined" tabIndex="6" onClick={handleClearOnClick}>
              {t('myPage.btnCancel')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

import { IMAGES } from 'assets'
import { Image } from 'components/commons'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'

//'../../assets/img/caret-right-solid.svg'
import arrowRight from '../../../../assets/img/caret-right-solid.svg'
import arrowRightRed from '../../../../assets/img/caret-right-solid-red.svg'

export default function MenuLink({ route, rendererRoute }) {
  let history = useHistory()
  const ArrowRight = () => <img src={arrowRight} alt="Arrow Icon" className="sidebar-arrowIcon"></img>
  const ArrowRightRed = () => <img src={arrowRightRed} alt="Arrow Red Icon" className="sidebar-arrowIcon"></img>
  return (
    <ul className={`menu-content ${route.path === '/compliance-check' ? 'marginTop4' : ''}`}>
      <NavLink
        onClick={rendererRoute}
        to={route.layout + route.path}
        className="nav-link text-link"
        activeClassName="active"
      >
        <div className="link-icon">
          {history.location.pathname.indexOf(route.layout + route.path) > -1 ? (
            <div className="menu-icon-red flex">
              <Image src={IMAGES.ICON.MENU[`${route.icon}Active`]} alt="Red Icon" className="sidebar-arrowIcon"></Image>
            </div>
          ) : (
            <div className="menu-icon-green flex">
              <Image src={IMAGES.ICON.MENU[route.icon]} alt="Green Icon" className="sidebar-arrowIcon"></Image>
            </div>
          )}
        </div>
        <div
          className={
            history.location.pathname.indexOf(route.layout + route.path) > -1 ? 'link-name active-link' : 'link-name'
          }
        >
          {route.menuName || ''}
        </div>
        <div className={`link-icon-arrow ${route.path === '/compliance-check' ? 'marginTop1_5' : ''}`}>
          {history.location.pathname.indexOf(route.layout + route.path) > -1 ? <ArrowRightRed /> : <ArrowRight />}
        </div>
      </NavLink>
    </ul>
  )
}

import './Login.css'

import { Button, Grid } from '@material-ui/core'
import { Lock, Person } from '@material-ui/icons'
import { login } from 'actions/userActions'
import { Card, FormInputs, MessageErrorDialog, ProgressDialog } from 'components/commons'
import { USER_LOGIN_FAILED, USER_LOGIN_SUCCESS } from 'constants/actionTypes/userActionTypes'
import { FLAG_BOOLEAN } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import userLoginValidate from 'validation/userValidate'

function Login() {
  const { i18n, t } = useTranslation()
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [showMessageErrorDialog, setShowMessageErrorDialog] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()

  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  const convertMessage = (message) => {
    if (message.includes('Incorrect')) {
      return t('login.msgErrorPasswordIncorrect')
    }

    if (message.includes('disabled')) {
      return t('login.msgErrorDisable')
    }

    if (message.includes('Password attempts exceeded')) {
      return t('login.msgErrorExceeded')
    }

    return t('login.msgErrorCannotAccess')
  }

  const handleLoginState = (type, errMessage = '') => {
    switch (type) {
      case USER_LOGIN_SUCCESS:
        setShowProgress(false)
        setRedirect(true)
        break
      case USER_LOGIN_FAILED:
        setShowMessageErrorDialog(true)
        setErrorMessage(convertMessage(errMessage))
        setShowProgress(false)
        break
      default:
        return
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    let { validate, error } = userLoginValidate(userId, password, i18n)

    if (!validate) {
      setShowMessageErrorDialog(true)
      setErrorMessage(error)
      return false
    }

    //入力したユーザー情報を取得
    const params = {
      userId: userId,
    }

    //メールとパスワードでログイン
    setShowProgress(true)
    dispatch(login(params, userId, password, i18n, handleLoginState))
  }

  useEffect(() => {
    if (showMessageErrorDialog) {
      const idUser = document.getElementById('ID_USER')
      idUser.focus()
      idUser.select()
    }
  }, [showMessageErrorDialog])

  useEffect(() => {
    if (userInfo) setRedirect(true)
  }, [userInfo])

  if (redirect) {
    if (userInfo?.KB_PASSW === FLAG_BOOLEAN.TRUE) {
      return <Redirect to="/customer/change-password" />
    }
    if (history.location.state?.pathname) {
      return <Redirect to="/customer/portal" />
    }
    if (history.location.state?.from) {
      return (
        <Redirect
          to={{
            pathname: '/customer/portal',
          }}
        />
      )
    }
    // if (history.location.state?.from) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: history.location.state?.from,
    //       }}
    //     />
    //   )
    // }
    return <Redirect to="/customer/portal" />
  }
  return (
    <div className="login">
      {showProgress && <ProgressDialog label={t('login.lblLoginProgress')} />}
      {showMessageErrorDialog && (
        <MessageErrorDialog message={errorMessage} showMessageErrorDialog={setShowMessageErrorDialog} />
      )}
      <Grid className="login-wrap">
        <Grid item md={12} xs={12} className="login-container">
          <div className="login-form">
            <Card
              title={t('login.lblTitle')}
              content={
                <form autoCapitalize="off" autoCorrect="off">
                  <FormInputs
                    label={t('login.lblId')}
                    icon={<Person viewBox="0 0 24 24" />}
                    type="text"
                    name="ID_USER"
                    id="ID_USER"
                    setValue={setUserId}
                    inputProps={{ tabIndex: '1', className: 'short-text' }}
                    autoComplete={'user-name'}
                  />
                  <FormInputs
                    label={t('login.lblPassword')}
                    icon={<Lock />}
                    type="password"
                    name="ID_PASSW"
                    id="ID_PASSW"
                    setValue={setPassword}
                    inputProps={{ tabIndex: '2', className: 'short-text' }}
                    autoComplete={'password'}
                  />
                  <div className="login-forget-password">
                    {t('login.lblPasswordForget')} <Link to="/forgot-password"> {t('login.linkPasswordForget')}</Link>
                  </div>
                  <div className="login-button">
                    <Button type="submit" onClick={handleLogin} tabIndex="3" className="btn-login">
                      {t('login.btnLogin')}
                    </Button>
                  </div>
                </form>
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login

import './ProgressDialog.css'

import LinearProgress from '@material-ui/core/LinearProgress'
import React from 'react'

function ProgressDialog({ label }) {
  return (
    <div className="wrapper__progress-dialog">
      <span>{label}</span>
      <LinearProgress />
    </div>
  )
}

export default ProgressDialog

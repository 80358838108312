import './SelectInput.css'

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { CheckSharp } from '@material-ui/icons'
import React from 'react'

export default function SelectInput({
  label,
  value,
  onChange,
  onClose,
  items = [],
  name,
  icon = <CheckSharp />,
  defaultIcon = true,
  required,
  variant = 'standard',
  ...rest
}) {
  const MAX_HEIGHT = 216
  return (
    <FormControl className="wrapper_select_input" variant={variant}>
      <Grid container alignItems="flex-end">
        <Grid item className="icon--check">
          {!defaultIcon ? '' : icon}
        </Grid>
        <Grid item>
          <InputLabel
            id="label"
            disableAnimation={true}
            className={`${!value ? 'label_unshink' : ''} ${required ? ' require_label' : ''}`}
          >
            {label}
          </InputLabel>
          <Select
            labelId="label"
            value={value}
            name={name}
            onChange={onChange}
            onClose={onClose}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: MAX_HEIGHT,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            {...rest}
          >
            {items.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  )
}

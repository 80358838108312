import './assets/main.css'
import './libs/I18n/i18n'

import Amplify from '@aws-amplify/core'
import Config from 'commons/config'
import { ProgressDialog } from 'components/commons'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistor, store } from 'store'

import App from './App'
Amplify.configure(Config.AMPLIFY)

moment.locale(process.env.REACT_APP_LOCALE)
moment.tz.setDefault('Asia/Tokyo')
const MainApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<ProgressDialog label={'ロード中です......'} />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(<MainApp />, document.getElementById('root'))

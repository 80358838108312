import axios from 'commons/baseUrl'
import {
  CLEAR_DATA,
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'
import { API_GET_CUST_USER } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const forgotPassword = (params, i18n) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASS_REQUEST, payload: params })

    let res = await axios.get(API_GET_CUST_USER, { params })
    if (res?.data?.data[0].custUser === '' && res?.data?.data[0].customer === '') {
      dispatch({ type: FORGOT_PASS_FAILED, payload: i18n.t('forgotPassword.msgErrorInvalid') })
      return
    }
    if (!res.data || res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: FORGOT_PASS_FAILED, payload: i18n.t('forgotPassword.msgErrorInvalid') })
      return
    }

    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: FORGOT_PASS_FAILED, payload: res.data.message })
      return
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: FORGOT_PASS_SUCCESS, payload: res.data.message })
      return
    }
  } catch (error) {
    dispatch({ type: FORGOT_PASS_FAILED, payload: error.data.message })
  }
}

const clearData = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DATA, payload: '' })
}

export { clearData, forgotPassword }
